import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Api, cancelRequest } from '../../api/Assinafy'
import { Avatar, Alert, Button, Dropdown, EmptyList, Form, Input, Icon, Modal, Spinner, Table, Tabs, Tooltip, Select } from '../../components'
import { signerEmpty } from '../../utils/SignersTemplate'
import classNames from 'classnames'
import { useParams } from 'react-router-dom';
const debounce = (fn, bufferInterval) => {
  let timeout;

  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(fn.apply.bind(fn, this, arguments), bufferInterval);
  }
}

const ApiSigner = Api('template/index')

const getSigners = debounce((value, signers, setNewSigners) => {
  ApiSigner({
    params: {
      'search': value,
      'per-page': 5
    }
  })
    .then(({ status, data: { data } }) => {
      if (status === 200) {
        if (data.length) {
          data.map((signer) => {
            setNewSigners((prevState) => {
              const prevSigners = typeof prevState === 'undefined' ? [] : prevState
    
              if (signers.find((prevSigner) => prevSigner.id === signer.id)) {
                return prevSigners
              }
    
              return [
                ...prevSigners,
                signer
              ]
            })
          })
        } else {
          setNewSigners([])
        }
      }
    })
}, 350)


const SignerTemplate = {}

const SignerListItem = (props) => {
  const { assignment_type, name, onClick } = props

  return (
    <div className="datalist-item" onClick={onClick}>
      <Avatar placeholder={assignment_type[0] || name[0]} />
      <span className="signer-name">
        {assignment_type}
      </span>
      <span className="signer-email">
        {name}
      </span>
    </div>
  )
};

const SignerDropdown = forwardRef(({
  items,
  active,
  colors, /* TODO: passar para context??? */
  onChangeItem,
  onRemoveItem,
  onClickNew
}, ref) => {
  const activeItem = active;

  return (
    <Dropdown
      ref={ref}
      button={activeItem && (
        <SignerDropdownItem {...activeItem}
          as="div"
          bgColor={colors[items.findIndex(s => s.id === activeItem.id)]?.color}
        />
      )}
      className="dropdown-signer"
    >
      {items.map((signer, i) => (
        <Dropdown.Item
          key={i}
          as={'button'}
          className={active && signer.name === active.name ? 'active' : ''}
        >
          <SignerDropdownItem
            {...signer}
            onClick={() => onChangeItem(signer, i)}
            bgColor={colors[i]?.color}
            as={'button'}
          />
          <Button variant="icon"
            className="btn-remove"
            onClick={() => onRemoveItem(signer)}
          >
            <Icon id="icon-trash" size="16" />
          </Button>
        </Dropdown.Item>
      ))}
      <Button
        variant="link"
        className="btn-signer-add"
        onClick={onClickNew}
      >
        <Icon id="icon-add" className="me-1" size="18" />
        ADICIONAR SIGNATÁRIOS
      </Button>
    </Dropdown>
  );
});

const SignerDropdownItem = ({ as: Component = 'button', type, ...props }) => {
  const { assignment_type, name, bgColor, onClick } = props;

  if (Component === 'button') {
    type = 'button';
  } else if (Component === 'title') {
    type = 'title';
  }

  return (
      <Component className="signer-item"
        type={type}
        onClick={onClick}
      >
        <Avatar placeholder={<Icon id={assignment_type === 'Signer' ? 'icon-initial' : 'icon-file_copy'} className="mb-1" size="20" />} bgColor={bgColor} />
        <div className="signer-info">
          {name === "TemplateEditor" ? 'Preparador do documento' : name}
          <div className="signer-email">
            {assignment_type === 'Signer' ? 'Assina o documento' : assignment_type === 'CopyReceiver' ? 'Recebe cópia assinada' : 'Informa os dados antes do envio'}
          </div>
        </div>
      </Component>
    )
};


const SignerAdd = ({ signers, onUpdate, onRemove, formValidation, setFormValidation, ...props }) => {
  const { uuid, id, name, assignment_type } = props
  const [tooltipShow, setTooltipShow] = useState(false)
  const [newSigner, setNewSigner] = useState({ uuid, id, name, assignment_type })
  const [newSigners, setNewSigners] = useState()
  const fullnameRef = useRef()
  const nameRef = useRef()
  const datalistNameRef = useRef()
  const datalistEmailRef = useRef()

  const updateNewSigner = (event) => {
    setNewSigner({
      ...newSigner,
      [event.target.name]: event.target.value
    });
  }

  const handleInputChange = (event) => {
    updateNewSigner(event)
  }

  const handleInputFocus = (event) => {
    const target = event.target

    if (newSigners?.length) {
      if (target.name === 'email') {
        datalistEmailRef.current.show()
      } else {
        datalistNameRef.current.show()
      }
    }
  }

  const handleInputBlur = (event) => {
    const target = event.target;
    const validationFilter = formValidation.filter((error) => error.uuid !== target.dataset.uuid || (error.uuid === target.dataset.uuid && error.name !== target.name));

    if (target.checkValidity()) {
      setFormValidation(validationFilter)
    } else {
      setFormValidation([
        ...validationFilter,
        { uuid: target.dataset.uuid, name: target.name, message: target.validationMessage }
      ])
    }

    let wait = 0;
    if (!event.relatedTarget) {
      wait = 250
    }
    if (target.name === 'email') {
      datalistEmailRef.current?.hide(wait)
    } else {
      datalistNameRef.current?.hide(wait)
    }
  }

  const tooltipMouseEnter = () => {
    setTooltipShow(true)
  }

  const tooltipMouseLeave = () => {
    setTooltipShow(false)
  }

  const showInputError = (inputName) => {
    const error = formValidation.find((error) => error.uuid === newSigner.uuid && error.name === inputName)
    if (error) {
      return (<div className="help-block">
        {error.message}
      </div>)
    }
  }

  const options = [
    { value: 'Signer', label: 'Assinar o documento' },
    { value: 'CopyReceiver', label: 'Receber uma cópia' }
  ];

  const optionsEditor = [
    { value: 'Editor', label: 'Preparar o documento' },
  ]

  useEffect(() => {
    onUpdate && onUpdate(newSigner, uuid || id)
  }, [newSigner])

  return (<Row className="row-signer">
    <Col xs="12" lg>
      <Form.Group>
        <Input ref={nameRef} name="name"
          autoComplete="off"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={name}
          label="Papel do signatário"
          data-uuid={newSigner.uuid}
          readOnly={newSigner.assignment_type === 'Editor'? newSigner.id : false}
          autoFocus={true}
          required
        />
        {showInputError('name')}
      </Form.Group>
    </Col>
    <Col xs="10" lg>
      <Form.Group>
        <Select ref={fullnameRef} name="assignment_type"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={assignment_type}
          label="Este papel precisa"
          options={newSigner.assignment_type === 'Editor'? optionsEditor : options}
          readOnly={newSigner.assignment_type === 'Editor'? newSigner.id : false}
          required
        />
        {showInputError('assignment_type')}
      </Form.Group>
    </Col>
    {
      newSigner.assignment_type !=='Editor' ?
      <Col xs="auto">
        <Button variant="icon" className="btn-remove"
          onMouseEnter={tooltipMouseEnter}
          onMouseLeave={tooltipMouseLeave}
          onClick={() => onRemove(uuid || id)}
        >
          <Icon id="icon-trash" size="28" />
          <Tooltip show={tooltipShow}>
            Excluir
          </Tooltip>
        </Button>
      </Col>
      :
      <Col xs="auto">
          <Icon size="28" />
      </Col>
    }
  </Row>)
};

const SignerNewModal = ({ show, signers, onCreate, onClose }) => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState([]);
  const [formData, setFormData] = useState({
    id: null,
    name: '',
    assignment_type: ''
  });
  const [newSigners, setNewSigners] = useState([]);
  const cancelToken = cancelRequest();
  const nameRef = useRef();
  const assignmentTypeRef = useRef();
  const { id: documentId } = useParams();

  const debounceSigners = useCallback(getSigners, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    setValidated(true);
    
    if (event.target.checkValidity()) {
      const { id, name, assignment_type } = formData;
      const addSignersContext = ({ id, name, assignment_type }) => {
        onCreate((prevState) => {
          if (prevState.find((signer) => signer.name === name)) {
            return prevState;
          }

          return [
            ...prevState, {
              id,
              name,
              assignment_type
            }
          ];
        });
      };

      setLoading(true);

      if (!id) {
        Api('templateRole/create')(documentId, { name, assignment_type })
          .then(({ status, data: { data } }) => {
            if (status === 200) {
              addSignersContext({ id: data.id, name, assignment_type });
              handleClose();
            }
          })
          .catch(({ response }) => {
            if (response) {
              setFormError([response.data.message]);
            }

            setLoading(false);
          });      
      } else {
        addSignersContext({ id, name, assignment_type });
        handleClose();
      }
    }
  };

  const updateNewSigner = (event) => setFormData({
    ...formData,
    [event.target.name]: event.target.value
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;

    if (value.length > 3) {

      setNewSigners();

      debounceSigners(value, signers, setNewSigners);
    }

    updateNewSigner(event);
  };
  const handleClose = () => {
    setFormData({});
    setNewSigners();
    setValidated(false);
    setLoading(false);
    setFormError([]);
    onClose();
  };

  const options = [
    { value: 'Signer', label: 'Assinar o documento' },
    { value: 'CopyReceiver', label: 'Receber uma cópia' }
  ];

  useEffect(() => {
    return () => {
      cancelToken.cancel();
    };
  }, []);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Form id="form-document-signers-new-modal" wasValidated={validated} onSubmit={handleSubmit}>
        <Modal.Header>
          <Button variant="icon" className="btn-close" onClick={handleClose}>
            <Icon id="icon-close" size="24" />
          </Button>
          <Modal.Title as={'div'} className="h2">Quem vai assinar ou receber o documento?</Modal.Title>
          Informe, abaixo, o papel de quem vai assinar o documento ou apenas receber uma cópia após todos assinarem.
        </Modal.Header>
        <Modal.Body>
          <Alert show={formError.length > 0} variant="warning" onClose={() => setFormError([])}>
            {formError.map((error, i) => <div key={i}>{error}</div>)}
          </Alert>
          <Row>
          <Col xs="12" lg>
            <Form.Group>
              <Input ref={nameRef} name="name"
                autoComplete="off"
                onChange={handleInputChange}
                defaultValue={formData.name}
                label="Papel do signatário"
                size="lg"
                required
              />
            </Form.Group>
          </Col>
          <Col xs="12" lg>
            <Form.Group>
              <Select ref={assignmentTypeRef} name="assignment_type"
                onChange={handleInputChange}
                defaultValue={formData.assignment_type}
                label="Este papel precisa"
                options={options}
                className="form-control-lg"
                required
              />
            </Form.Group>
          </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="transparent" size="lg" type="button" onClick={handleClose} className="me-2" disabled={loading}>
            CANCELAR
          </Button>
          <Button variant="primary" size="lg" submit disabled={loading}>
            SALVAR
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const SignerListModal = ({
  show,
  onClose,
  newSigners,
  setNewSigners
}) => {
  const [signers, setSigners] = useState()
  const [signersSelected, setSignersSelected] = useState([])
  const [errors, setErrors] = useState([])
  const [tabKey, setTabKey] = useState('all')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [paginationPageCount, setPaginationPageCount] = useState(-1)
  const [search, setSearch] = useState('')

  const tabAllRef = useRef()
  const cancelToken = cancelRequest()

  const handleClose = () => {
    onClose()
    setSigners()
    setPage(0)
    setSearch('')
  }

  const handleCheckboxChangeAll = () => {
    if (signersSelected.length) {
      setSignersSelected([])
    } else {
      setSignersSelected(signers.filter(s => !newSigners.some(n => n.id === s.id)))
    }
  }

  const handleCheckboxChange = (signer) => {
    if (signersSelected.includes(signer)) {
      setSignersSelected(signersSelected.filter(d => d.email !== signer.email))
    } else {
      setSignersSelected([...signersSelected, signer])
    }
  }

  const handleAddSigners = () => {
    if (signersSelected.length) {
      signersSelected.map((signer) => {
        if (!newSigners.find(s => s.id === signer.id)) {
          const { id, full_name, email } = signer
          setNewSigners(prevState => {
            return [
              ...prevState,
              signerEmpty({ id, full_name, email })
            ]
          })
        }
      })

      setNewSigners((prevState) => prevState.filter((s) => s.email && s.full_name))
    }

    setSignersSelected([])
    handleClose()
  }

  const getSigners = (nextPage = page + 1) => {
    setTabKey('all')

    if (nextPage === 1) {
      setPaginationPageCount(-1)
    }

    if (
      paginationPageCount > 0 && paginationPageCount === page
    ) {
      return
    }

    setLoading(true)

    ApiSigner({
      ...cancelToken.config,
      params: {
        'page': nextPage,
        'per-page': 10,
        search
      }
    })
      .then(({ status, headers, data: { data } }) => {
        if (status === 200) {
          if (data.length) {
            setPage(nextPage)
            setPaginationPageCount(parseInt(headers['x-pagination-page-count']))

            setSigners([...signers || [], ...data])
          } else {
            setSigners([])
          }
        }

        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const debounceSigners = useCallback(debounce(getSigners, 350), [signers, newSigners, search])

  const handleSearchSubmit = (event) => {
    event.preventDefault()
    setSigners()
    debounceSigners(1)
  }

  const handleSearchChange = ({ target }) => {
    setSearch(target.value)
  }

  const signersTotalMessage = (total) => {
    const addS = total > 1 ? 's' : ''
    return total
      ? `${total} contato${addS} selecionado${addS}`
      : 'Nenhum contato selecionado'
  }

  useImperativeHandle(tabAllRef, () => ({
    loading,
    getSigners
  }))

  useEffect(() => {
    if (!show && !search) {
      return
    }

    setSigners()
    debounceSigners(1)

    return () => {
      cancelToken.cancel()
    }
  }, [search])

  useEffect(() => {
    setSignersSelected([])

    if (!show || signers?.length) {
      return
    }

    getSigners(1)

    return () => {
      cancelToken.cancel()
    }
  }, [show])

  useEffect(() => {
    if (!show) {
      return
    }

    const tabPanes = document.querySelectorAll('.tab-pane[data-scroll]')
    const handleScroll = (e) => {
      const target = e.target
      if (!tabAllRef.current.loading && target.clientHeight + target.scrollTop > (target.scrollHeight - 5)) {
        tabAllRef.current.getSigners()
      }
    }

    tabPanes.forEach((tabPane) => {
      tabPane.addEventListener('scroll', handleScroll)
    })

    return () => {
      tabPanes.forEach((tabPane) => {
        tabPane.removeEventListener('scroll', handleScroll)
      })

      cancelToken.cancel()
    }
  }, [show])

  return (<Modal show={show}
    className="modal-contacts"
    centered
    onHide={handleClose}
  >
    <Modal.Header>
      {onClose && <Button variant="icon" className="btn-close"
        onClick={handleClose}
      >
        <Icon id="icon-close" size="24" />
      </Button>}
      <Modal.Title as={'div'} className="h2">
        Contatos
      </Modal.Title>
      <Alert show={errors.length} variant="warning" onClose={() => setErrors([])}>
        {errors.map((error) => error)}
      </Alert>
      <Form.Group>
        <Input.Group>
          <Input.Search
            name="search"
            placeholder="Busque pelo nome ou e-mail do contato"
            onChange={handleSearchChange}
            autoFocus
          />
          <Button variant="action"
            onClick={handleSearchSubmit}
          >
            <span className="d-lg-none">
              <Icon id="icon-zoom" size="20" />
            </span>
            <span className="d-none d-lg-block">
              BUSCAR
            </span>
          </Button>
        </Input.Group>
      </Form.Group>
    </Modal.Header>
    <Modal.Body>
      <Tabs
        activeKey={tabKey}
        onSelect={setTabKey}
      >
        <Tabs.Tab ref={tabAllRef} eventKey="all" title="Todos" data-scroll>
          {signers && !signers.length
            ? <EmptyList
              icon="icon-person"
              title="Você ainda não possui contatos na sua lista"
            />
            : <Table
              className="table-bordered mb-0"
              header={[
                {
                  name: <Form.Check>
                    <Input.Checkbox
                      onChange={handleCheckboxChangeAll}
                      checked={signersSelected.length}
                      className={(signersSelected.length < signers?.length) ? 'has-checked' : ''}
                      disabled={!signers?.length}
                    />
                  </Form.Check>,
                  className: 'col-action'
                },
                { name: 'Nome' },
                { name: 'E-mail' }
              ]}
              rows={signers}
              renderRow={(row, i) => {
                const isSelected = newSigners.findIndex(ss => ss.id === row.id) !== -1
                const isChecked = signersSelected.findIndex(ss => ss.id === row.id) !== -1
                const trClassName = classNames(isSelected && 'signer-selected', isChecked && 'signer-checked')

                return (<tr
                    key={row.id}
                    className={trClassName}
                    onClick={() => handleCheckboxChange(row)}
                  >
                  <td><Form.Check>
                    <Input.Checkbox
                      onChange={() => handleCheckboxChange(row)}
                      checked={isSelected || isChecked}
                    />
                  </Form.Check></td>
                  <td>
                    <div className="signer-header">
                      <Avatar placeholder={row.full_name[0] || row.email[0]} />
                      <div className="signer-title">{row.full_name}</div>
                    </div>
                  </td>
                  <td>{row.email}</td>
                </tr>)
              }}
            />
          }
          {(paginationPageCount > page) &&
            <Spinner className="pagination-spinner" />
          }
        </Tabs.Tab>
        <Tabs.Tab eventKey="selected" title="Selecionados">
          <Table
            className="table-bordered mb-0"
            header={[
              {
                name: <Form.Check>
                  <Input.Checkbox
                    onChange={handleCheckboxChangeAll}
                    checked={signersSelected.length}
                    className={(signersSelected.length < signers?.length) ? 'has-checked' : ''}
                    disabled={!signersSelected.length}
                  />
                </Form.Check>,
                className: 'col-action'
              },
              { name: 'Nome' },
              { name: 'E-mail' }
            ]}
            rows={signersSelected}
            renderRow={(row, i) => <tr key={row.id}>
              <td><Form.Check>
                <Input.Checkbox
                  onChange={() => handleCheckboxChange(row)}
                  checked={signersSelected.includes(row)}
                />
              </Form.Check></td>
              <td>
                <div className="signer-header"
                  onClick={() => handleCheckboxChange(row)}
                >
                  <Avatar placeholder={row.full_name[0] || row.email[0]} />
                  <div className="signer-title">{row.full_name}</div>
                </div>
              </td>
              <td>{row.email}</td>
            </tr>}
          />
        </Tabs.Tab>
      </Tabs>
    </Modal.Body>
    {!!signers?.length && <Modal.Footer>
      <b>
        {signersTotalMessage(signersSelected.filter(s => !newSigners.some(ss => ss.id === s.id)).length)}
      </b>
      <Button variant="primary"
        onClick={handleAddSigners}
      >
        ADICIONAR
      </Button>
    </Modal.Footer>}
  </Modal>)
}


SignerTemplate.Add = SignerAdd
SignerTemplate.ListItem = SignerListItem
SignerTemplate.Dropdown = SignerDropdown
SignerTemplate.NewModal = SignerNewModal
SignerTemplate.ListModal = SignerListModal

export default SignerTemplate;