import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { usePrepareContext } from '../../routes/PrepareRouteTemplate';
import { Api } from '../../api/Assinafy';
import { Dashboard, Scroll, Section } from '../../layout';
import { PrepareNavbar } from '../../layout/Prepare';
import { Form, Button, Icon, Alert } from '../../components';
import SignerTemplate from '../../components/SignerTemplate';
import { signerEmpty } from '../../utils/SignersTemplate';

import './styles.scss';

const TemplateSigners = ({ virtual }) => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValidationErrors, setFormValidationErrors] = useState([]);
  const [submissionErrors, setSubmissionErrors] = useState([]);
  const [documentContext, setDocumentContext, signersContext, setSignersContext] = usePrepareContext();
  const [newSigners, setNewSigners] = useState([]);
  const navigate = useNavigate();
  const { id: documentId } = useParams();

  // Adds a new signer to the context, ensuring no duplicates
  const addSignerToContext = ({ id, name, assignment_type }) => {
    setSignersContext((previousSigners) => {
      if (previousSigners.some((signer) => signer.name === name)) return previousSigners;

      return [
        ...previousSigners,
        { id, name, assignment_type },
      ];
    });
  };

  // Handles form submission for signers
  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);

    if (event.target.checkValidity()) {
      setLoading(true);

      // Splits signers into existing and new
      const [existingSigners, newSignersToCreate] = newSigners.reduce(
        ([existingGroup, newGroup], signer) => {
          if (signersContext.some((existingSigner) => existingSigner.id === signer.id || existingSigner.name === signer.name)) {
            return [[...existingGroup, signer], newGroup];
          }
          return [existingGroup, [...newGroup, signer]];
        },
        [[], []]
      );

      // Updates existing signers
      const updatePromises = existingSigners
      // .filter((signer) => signer.assignment_type !== 'Editor' && signer.name !== 'Editor')
      .map((signer) => {
        const { id, name, assignment_type } = signer;
    
        const payload = { name, assignment_type };
    
        return Api('templateRole/update')(documentId, id, payload)
          .then(({ data }) => {
            setSignersContext((previousSigners) =>
              previousSigners.map((existingSigner) =>
                existingSigner.id === id ? { ...existingSigner, ...data.data } : existingSigner
              )
            );
            return { success: true };
          })
          .catch(({ response }) => {
            return { success: false, error: response?.data?.message };
          });
      });

      // Creates new signers
      const createPromises = newSignersToCreate
      // .filter((signer) => signer.assignment_type !== 'Editor' && signer.name !== 'Editor')
      .map((signer) => {
        const { name, assignment_type } = signer;
    
        const payload = { name, assignment_type };
    
        return Api('templateRole/create')(documentId, payload)
          .then(({ data }) => {
            addSignerToContext({ id: data.data.id, name, assignment_type });
            return { success: true };
          })
          .catch(({ response }) => {
            return { success: false, error: response?.data?.message };
          });
      });

      // Processes all operations
      Promise.all([...updatePromises, ...createPromises])
        .then((results) => {
          const errors = results.filter((result) => !result.success);
          if (errors.length) {
            setSubmissionErrors(errors.map((error) => error.error));
          } else {
            navigate(`/dashboard/templates/${documentId}/prepare`);
          }
        })
        .finally(() => setLoading(false));
    } else {
      // Validates form fields
      setFormValidationErrors(
        Array.from(event.target.elements)
          .filter((formElement) => formElement.name && formElement.dataset)
          .map((formElement) => ({
            uuid: formElement.dataset.uuid,
            name: formElement.name,
            message: formElement.validationMessage,
          }))
      );
    }
  };

  // Adds an empty signer
  const addNewSigner = () => {
    setNewSigners((previousSigners) => [...previousSigners, signerEmpty()]);
  };

  // Updates a signer's data
  const updateSigner = (updatedSigner, uuid) => {
    setNewSigners((previousSigners) =>
      previousSigners.map((signer) => (signer.uuid === uuid ? updatedSigner : signer))
    );
  };

  // Removes a signer from the list
  const removeSigner = (signer) => {
    let remainingSigners = signer.id
      ? newSigners.filter((existingSigner) => existingSigner.id !== signer.id)
      : newSigners.filter((existingSigner) => existingSigner.uuid !== signer.uuid);

    if (signer.id) {
      Api('templateRole/delete')(documentId, signer.id).catch(({ response }) =>
        setSubmissionErrors([response?.data?.message || 'Error removing signer.'])
      );
    }

    if (!remainingSigners.length) {
      remainingSigners = [signerEmpty()];
    }

    setNewSigners(remainingSigners);
  };

  // Loads initial data
  useEffect(() => {
    Api('template/view')(documentId)
      .then(({ data }) => {
        const documentData = data.data;
        setDocumentContext(documentData);
        const existingSigners = documentData.roles.filter((signer) => signer.assignment_type !== 'Editor' && signer.name !== 'TemplateEditor');
        setSignersContext(documentData.roles);

        setNewSigners(existingSigners.length ? existingSigners.map(signerEmpty) : [signerEmpty()]);
      })
      .catch(() => navigate('/dashboard/templates'));
  }, [documentId, navigate]);

  return (
    <Form id="form-document-signers" className="form-prepare" onSubmit={handleSubmit}>
      <PrepareNavbar
        document={documentContext}
        step="signers"
        submitLoading={loading}
        text="Revisão e conclusão"
        url="templates"
        textButtonSubmit="CONTINUAR"
      />
      <Scroll>
        <Dashboard.Content>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Dashboard.Header title="Signatários" />
              <Section className="section-document">
                <Section.Header
                  title="Quem vai assinar ou receber o documento?"
                  opening="Informe abaixo os papéis de quem vai assinar o documento ou apenas receber uma cópia após todos assinarem"
                  example="Exemplo: locador, locatário, testemunha"
                />
                <Alert show={!!submissionErrors.length} variant="warning" onClose={() => setSubmissionErrors([])}>
                  {submissionErrors.map((error, index) => (
                    <div key={index}>{error}</div>
                  ))}
                </Alert>
                {newSigners.map((signer) => (
                  <SignerTemplate.Add
                    key={signer.uuid}
                    {...signer}
                    signers={newSigners}
                    onUpdate={updateSigner}
                    onRemove={() => removeSigner(signer)}
                    formValidation={formValidationErrors}
                    setFormValidation={setFormValidationErrors}
                  />
                ))}
                <Form.Footer horizontal>
                  <Button variant="primary" onClick={addNewSigner}>
                    <Icon id="icon-add" className="me-1" size="18" />
                    ADICIONAR MAIS
                  </Button>
                </Form.Footer>
              </Section>
            </Col>
          </Row>
        </Dashboard.Content>
      </Scroll>
    </Form>
  );
};

export default TemplateSigners;
