import React, {
	useState,
	useEffect,
	forwardRef,
	useRef,
	useCallback
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Api,
	getSession,
	urlWithAccessToken,
	cancelRequest
} from '../../api/Assinafy';
import { signerColors } from '../../utils/SignersTemplate';
import { usePrepareContext } from '../../routes/PrepareRouteTemplate';
import { PrepareNavbar } from '../../layout/Prepare';
import PageCanvas from '../../components/PageCanvas';
import {
	Form,
	Button,
	Input,
	Icon,
	Viewport,
	Tooltip,
	Modal
} from '../../components';
import SignerTemplate from '../../components/SignerTemplate';
import _ from 'lodash';

import iconClose from '../../assets/images/icon-close.svg';

const LIMIT_BOTTON_TO_MOVE = 180;
const CLOSE_ICON_PADDING = 8;

const TemplatePrepare = ({ virtual }) => {
	const [documentContext, , signersContext, setSignersContext] =
		usePrepareContext();
	const [pages, setPages] = useState([]);
	const [signersUsed, setSignersUsed] = useState([]);
	const [submitDisabled, setSubmitDisabled] = useState(true);
	const [modalNewShow, setModalNewShow] = useState(false);
	const [modalRemove, setModalRemove] = useState();
	const { id: documentId } = useParams();
	const navigate = useNavigate();

	const handleSubmit = useCallback(
		(event) => {
			event.preventDefault();
			navigate(`/dashboard/templates/${documentId}/review`);
		},
		[documentId, navigate]
	);

	useEffect(() => {
		if (!window.pageCanvas) {
			window.pageCanvas = [];
		}
		if (!pages.length && documentContext.pages) {
			setPages(() => {
				return documentContext.pages
					.sort((a, b) => a.number - b.number)
					.map((pageInfo) => {
						pageInfo.pageUrl = urlWithAccessToken(pageInfo.download_url);
						return (
							<PageCanvas
								key={pageInfo.id}
								{...pageInfo}
								totalPages={documentContext.pages.length}
							/>
						);
					});
			});
		}

		return () => {
			window.pageCanvas = [];
		};
	}, [documentContext, pages]);

	useEffect(() => {
		if (!virtual) {
			const requiredSigners = signersContext.filter(
				(signer) => signer.assignment_type === 'Signer'
			);
	
			const usedSigners = signersUsed.filter((id) =>
				requiredSigners.some((signer) => signer.id === id)
			);

			setSubmitDisabled(usedSigners.length < requiredSigners.length);
		} else {
			setSubmitDisabled(false);
		}
	}, [signersUsed, signersContext, virtual]);	

	return (
		<>
			<Form
				id="form-document-prepare"
				className="form-prepare"
				onSubmit={handleSubmit}
			>
				<PrepareNavbar
					document={documentContext}
					step="prepare"
					submitDisabled={submitDisabled}
					text="Revisão e conclusão"
					url="templates"
					textButtonSubmit="CONTINUAR"
				/>

				{!virtual && (
					<Aside
						documentId={documentId}
						signersUsed={signersUsed}
						setSignersUsed={setSignersUsed}
						setModalNewShow={setModalNewShow}
						setModalRemove={setModalRemove}
					/>
				)}
				<Viewport>
					<div className="viewport-content">{pages}</div>
				</Viewport>
			</Form>
			<SignerTemplate.NewModal
				show={modalNewShow}
				signers={signersContext}
				onCreate={setSignersContext}
				onClose={() => setModalNewShow(false)}
			/>
			<Modal.Alert
				show={!!modalRemove}
				variant="danger"
				title="Deseja realmente remover o signatário deste documento?"
				description={modalRemove?.description}
				onClose={() => setModalRemove()}
			>
				<Modal.Footer>
					<Button
						variant="light"
						submit
						onClick={() => setModalRemove()}
					>
						CANCELAR
					</Button>
					<Button
						variant="danger"
						submit
						onClick={modalRemove?.onConfirm}
					>
						<Icon id="icon-trash" className="me-1" size="16" />
						REMOVER
					</Button>
				</Modal.Footer>
			</Modal.Alert>
		</>
	);
};

const Aside = forwardRef(
	({ signersUsed, setSignersUsed, setModalNewShow, setModalRemove }, ref) => {
		const [
			documentContext,
			,
			signersContext,
			setSignersContext,
			fieldsContext,
			setFieldsContext
		] = usePrepareContext();
		const [signerSelected, setSignerSelected] = useState({});
		const [fieldTypes, setFieldTypes] = useState([]);
		const [color, setColor] = useState('transparent');
		const [tooltipShow, setTooltipShow] = useState(false);
		const accountId = getSession().accounts[0].id;
		const cancelToken = cancelRequest();
		const dropdownRef = useRef();

		const fetchFieldsContext = (event, setSignersUsed) => {
			if (event === 'added' || event === 'removed') {
				setSignersUsed([]);
			}

			return window.pageCanvas.map((canvas) => {
				return {
					page_id: canvas.page.id,
					fields: canvas
						.getObjects()
						.filter((v) => v.data)
						.map((obj) => {
							const { font, backgroundColor, signer, fieldType } = obj.data;

							if (event === 'added' || event === 'removed') {
								setSignersUsed((prevState) => {
									if (!prevState.find((id) => id === signer.id)) {
										return [...prevState, signer.id];
									}
									return [...prevState];
								});
							}

							return {
								role_id: signer.id,
								field_id: obj.id,
								label: fieldType.name,
								display_settings: {
									...obj.getBoundingRect(),
									fontFamily: font.fontFamily,
									fontSize: font.fontSize,
									backgroundColor: backgroundColor
								}
							};
						})
				};
			});
		};

		const closeIcon = (textBox, iconCallback) => {
			const fabric = window.fabric;

			return fabric.loadSVGFromURL(
				`${iconClose}`,
				function (objects, options) {
					const group = fabric.util.groupSVGElements(objects);
					textBox.closeIcon = group;
					textBox.closeIcon.left =
						textBox.left +
						textBox.width +
						textBox.padding +
						CLOSE_ICON_PADDING;
					textBox.closeIcon.top = textBox.top;
					textBox.closeIcon.hoverCursor = 'pointer';
					iconCallback(group);
				}
			);
		};

		const findFieldInPage = (fieldId) => {
			return window.pageCanvas.find((pc) =>
				pc.getObjects().find((obj) => obj.id === fieldId)
			);
		};

		const newTextBox = (currentPageCanvas, itemType, attributes = {}) => {
			let name = signerSelected.name;
			if (attributes?.data) {
				name = attributes.data.signer.name;
			}

			const fabric = window.fabric;
			const { width, height, top } =
				currentPageCanvas.lowerCanvasEl.getBoundingClientRect();
			const textBox = new fabric.Textbox(`${itemType.name} - ${name === 'TemplateEditor' ? 'Preparador do documento' : name}`, {
				left: width / 2 + 30,
				top: height / 2 - top + 150,
				fontFamily: 'Arial',
				id: fieldTypes.find((v) => v.name === itemType.name).id,
				padding: 10,
				fontSize: 22,
				width: 400,
				textAlign: 'center',
				height: 30,
				color: color.color,
				backgroundColor: color.bgColor,
				objectCaching: false,
				lockRotation: true,
				lockScalingY: true,
				lockScalingFlip: true,
				...attributes
			});

			textBox.data = {
				fieldType: itemType,
				signer: signerSelected,
				backgroundColor: textBox.backgroundColor,
				font: {
					fontFamily: textBox.fontFamily,
					fontSize: textBox.fontSize
				},
				...attributes.data
			};

			textBox.closeIcon = closeIcon(textBox, (icon) => {
				currentPageCanvas.add(icon);
				icon.on('mousedown', () => {
					removeTextBox(currentPageCanvas, textBox);
					currentPageCanvas.requestRenderAll();
				});
			});

			textBox.onMoving = (e) => {
				e.target.closeIcon.selectable = false;
				e.target.closeIcon.left =
					e.target.left +
					e.target.padding +
					e.target.width +
					CLOSE_ICON_PADDING;
				e.target.closeIcon.top = e.target.top;
				e.target.closeIcon.setCoords();
				e.target.setCoords();
			};

			textBox.on('mouseup', () => currentPageCanvas.requestRenderAll());

			return textBox;
		};

		const moveTextBox = (
			textBox,
			fromPageCanvas,
			toPageCanvas,
			direction = 'next'
		) => {
			removeTextBox(fromPageCanvas, textBox);

			if (findFieldInPage(textBox.id)) {
				return;
			}

			const directionY = {
				previous:
					toPageCanvas.height - textBox.height - textBox.padding - 10,
				next: textBox.padding + 10
			};
			const newObjAttributes = {
				top: directionY[direction],
				left: textBox.left,
				id: textBox.id,
				backgroundColor: textBox.backgroundColor,
				data: textBox.data
			};

			addFieldToCanvas(
				toPageCanvas,
				textBox.data.fieldType,
				newObjAttributes
			);
		};

		const removeTextBox = (canvas, textBox) => {
			canvas.remove(textBox.closeIcon);
			canvas.remove(textBox);
			setFieldsContext(fetchFieldsContext('removed', setSignersUsed));
		};

		const addFieldToCanvas = (currentPageCanvas, itemType, attributes = {}) => {
			const textBox = newTextBox(currentPageCanvas, itemType, attributes);
			currentPageCanvas.add(textBox);

			setFieldsContext(fetchFieldsContext('added', setSignersUsed));

			currentPageCanvas.on('object:modified', () => {
				setFieldsContext(fetchFieldsContext('modified', setSignersUsed));
			});

			currentPageCanvas.on('object:moving', (event) => {
				const currentTextBox = event.target;
				if (!currentTextBox) return;

				currentTextBox.onMoving(event);

				const pageNumber = currentPageCanvas.page.number;
				if (currentTextBox.top > currentPageCanvas.height) {
					const nextPageCanvas = getNextCanvasPage(pageNumber);
					if (nextPageCanvas) {
						moveTextBox(currentTextBox, currentPageCanvas, nextPageCanvas);
					}
				} else if (currentTextBox.top + currentTextBox.height < 0) {
					const prevPageCanvas = getPreviousCanvasPage(pageNumber);
					if (prevPageCanvas) {
						moveTextBox(currentTextBox, currentPageCanvas, prevPageCanvas, 'previous');
					}
				}
			});

			const collisions = []
			const objects = currentPageCanvas.getObjects().filter(v => v.data)
			let objectsI = -1

			while (++objectsI < objects.length) {
				const obj = objects[objectsI]
				if (obj.id !== textBox.id) {
					collisions.push(obj)
				}
			}

			Promise.all(collisions).then(results =>
				Promise.all(results.map(({ height }) =>
					textBox.top += (height + 20)
				))
			)

			currentPageCanvas.setActiveObject(textBox);
			currentPageCanvas.requestRenderAll();

			return textBox;
		};

		const addFieldToDocument = (type) => {
			const pageElements = document.getElementsByClassName('lower-canvas');
			const pageNumber = parseInt(Array.from(pageElements).find((page) => {
				const { top, bottom } = page.getBoundingClientRect();
				return top < window.innerHeight && bottom >= LIMIT_BOTTON_TO_MOVE;
			}).getAttribute('number'));
			const pageCanvas = window.pageCanvas[pageNumber - 1];

			addFieldToCanvas(pageCanvas, type);
		};

		const getNextCanvasPage = (currentNumber) => {
			return window.pageCanvas.find(
				(canvas) => canvas.page.number === currentNumber + 1
			);
		};

		const getPreviousCanvasPage = (currentNumber) => {
			return window.pageCanvas.find(
				(canvas) => canvas.page.number === currentNumber - 1
			);
		};

		const handleChangeSigner = (signer, i) => {
			if (!signer) return;

			const color = signerColors[i] || signerColors[0];
			setColor(color);
			document.documentElement.style.setProperty(
				'--action-bg-color',
				color.bgColor
			);
			document.documentElement.style.setProperty(
				'--action-color',
				color.color
			);

			setSignerSelected(signer);
			if (dropdownRef.current) {
				dropdownRef.current.close();
			}
		};

		const signerRemove = ({ id, name }) => {
			const fieldBySigner = fieldsContext
				.map((p) => p.fields.find((f) => f.signer.id === id))
				.filter((r) => r !== undefined);

			if (fieldBySigner.length) {
				return window.alert(
					'O signatário não pôde ser removido.\nRemove os campos relacionados ao signatário e tente novamente.'
				);
			} else {
				setModalRemove({
					onConfirm: () => {
						if (id !== signerSelected.id) {
							setSignersContext(
								signersContext.filter((s) => s.id !== id)
							);
						}
						setModalRemove();
					}
				});
			}
		};

		const tooltipMouseEnter = () => {
			setTooltipShow(true);
		};

		const tooltipMouseLeave = () => {
			setTooltipShow(false);
		};

		const fieldTypeRender = (field, i) => {
			return (
				<Form.Check key={i}>
					<Icon id={`icon-${field.type}`} size="20" />
					<Input.Radio
						key={field.id}
						defaultChecked={i === 0}
						onClick={() => {
							addFieldToDocument(field);
						}}
					/>
					{field.name}
				</Form.Check>
			);
		};

		useEffect(() => {
			Api('field/index')(accountId, {
				...cancelToken.config,
				params: {
					include_standard: true
				}
			}).then(({ status, data: { data } }) => {
				if (status === 200) {
					setFieldTypes(data);
				}
			});
		}, [accountId]);

		useEffect(() => {
			if (!window.pageCanvas) {
				return;
			}

			fieldsContext.forEach(({ page_id, fields }) => {
				const canvas = window.pageCanvas.find((pc) => pc.page.id === page_id);
				if (canvas) {
					fields.forEach((field) => {
						const {
							field_id,
							role_id,
							label,
							display_settings: {
								left,
								top,
								width,
								height,
								fontFamily,
								fontSize,
								backgroundColor
							}
						} = field;

						const newObjAttributes = {
							id: field_id,
							top,
							left,
							width,
							backgroundColor,
							data: {
								fieldType: { name: label.split(' - ')[0] },
								signer: { id: role_id, name: label.split(' - ')[1] },
								backgroundColor,
								font: { fontFamily, fontSize }
							}
						};

						if (!findFieldInPage(field_id)) {
							addFieldToCanvas(canvas, newObjAttributes.data.fieldType, newObjAttributes);
						}
					});
				}
			});
		}, [window.pageCanvas, fieldsContext]);

		useEffect(() => {
			handleChangeSigner(signersContext[0]);
		}, [signersContext]);

		return (
			<aside className="aside">
				<Form.Group className="group-signers">
					<Form.Label>Signatários</Form.Label>

					{!signersContext.length ? (
						<Button
							variant="info"
							onClick={() => setModalNewShow(true)}
						>
							<Icon id="icon-add" className="me-1" size="18" />
							ADICIONAR SIGNATÁRIOS
						</Button>
					) : (
						<>
							<SignerTemplate.Dropdown
								ref={dropdownRef}
								active={signerSelected}
								colors={signerColors}
								items={signersContext}
								onChangeItem={handleChangeSigner}
								onRemoveItem={signerRemove}
								onClickNew={() => setModalNewShow(true)}
							/>
							<Form.Text>
								<b>
									{
										signersContext
											.filter((signer) => signer.assignment_type === 'Signer') 
											.filter((signer) => !signersUsed.includes(signer.id))
											.length
									}
								</b>{' '}
								signatários faltando
								<span
									className="ms-1"
									onMouseEnter={tooltipMouseEnter}
									onMouseLeave={tooltipMouseLeave}
								>
									<Icon
										id="icon-help"
										size="16"
										color="#8C97A0"
									/>
									<Tooltip show={tooltipShow}>
										Para concluir esse documento
										<br />
										todos os signatários associados
										<br />
										devem conter ao menos uma ação
										<br />
										no documento.
									</Tooltip>
								</span>
							</Form.Text>
						</>
					)}
				</Form.Group>

				<Form.Group className="group-fields">
					<Form.Label>Ações</Form.Label>

					<div className="form-subgroup">
						{fieldTypes
							.filter((f) => f.is_standard)
							.map(fieldTypeRender)}
					</div>
					<div className="form-subgroup">
						{fieldTypes
							.filter((f) => !f.is_standard)
							.map(fieldTypeRender)}
					</div>
				</Form.Group>
			</aside>
		);
	}
);

export default TemplatePrepare;
