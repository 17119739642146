import React, { useState, useRef, forwardRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR'
import classNames from 'classnames';
import { Button, Icon } from '../';
import creditCardType from 'credit-card-type';

import 'react-datepicker/dist/react-datepicker.css';

import './styles.scss';

const Input = forwardRef(({ variant, size, label, ...props }, ref) => {
  const { className, placeholder, type } = props
  const prefix = 'form-control';
  const newClassName = classNames(prefix, variant && `${prefix}-${variant}`, size && `${prefix}-${size}`, className);

  return (<>
    <input ref={ref} {...props}
      type={type || 'text'}
      className={newClassName}
      placeholder={(!label && placeholder) || (label && ` `)}
    />
    {label && <label className="form-label">
      {label}
    </label>}
  </>);
});

const InputEmail = forwardRef((props, ref) => {
  // TODO: fix pattern [a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}"

  return (
    <Input ref={ref} {...props} type="email" />
  );
});

const InputTel = forwardRef((props, ref) => {
  const setMask = (event) => {
    const target = event.target;
    target.value = target.value.replace(/\D/g, '');
  }

  const handleChange = (event) => {
    setMask(event)

    if (props.onChange) {
      props.onChange(event)
    }
  }

  return (
    <Input ref={ref} {...props} onChange={handleChange} type="tel" />
  );
});

const InputPhone = forwardRef((props, ref) => {
  const inputRef = useRef()

  const setMask = (event) => {
    const target = event.target;
    let value = target.value.replace(/\D/g, '')
      .replace(/^(\d{1})/, '($1')
      .replace(/^(\(\d{2})(\d)/, '$1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2');

    target.value = value
  }

  const handleChange = (event) => {
    setMask(event)

    if (props.onChange) {
      props.onChange(event)
    }
  }

  return (
    <InputTel ref={inputRef} {...props} onChange={handleChange} maxLength="15" pattern="\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}" />
  );
});

const InputCpf = forwardRef((props, ref) => {
  const setMask = (event) => {
    const target = event.target;
    const value = target.value.replace(/\D/g, '')
      .replace(/^(\d{3})(\d)/, '$1.$2')
      .replace(/^(\d{3}\.\d{3})(\d)/, '$1.$2')
      .replace(/^(\d{3}\.\d{3}\.\d{3})(\d)/, '$1-$2')

    target.value = value
  }

  const validator = (value) => {
    const cpf = value.replace(/[^\d]+/g, '');
    if (cpf === '') return false;
    const fd = cpf.substring(0, 9), ld = cpf.substring(9, 11);
    for (let v = 0; 10 > v; v++) if ('' + fd + ld === '' + v + v + v + v + v + v + v + v + v + v + v) return false;
    let add = 0, rev = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;
    return true;
  }

  const handleChange = (event) => {
    const target = event.target

    setMask(event)

    if (!validator(target.value)) {
      target.setCustomValidity('Número de CPF inválido. Insira um CPF válido.')
    } else {
      target.setCustomValidity('')
    }

    if (props.onChange) {
      props.onChange(event)
    }
  }

  return (
    <InputTel ref={ref} {...props}
      maxLength="14"
      pattern="[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}"
      onChange={handleChange}
    />
  );
});

const InputPassword = forwardRef((props, ref) => {
  const [passwordShown, setPasswordShown] = useState(false)

  const handleToggle = () => {
    setPasswordShown(!passwordShown)
  }

  return (<>
    <Input ref={ref} {...props}
      type={passwordShown ? 'text' : 'password'}
      minLength="8"
    />
    <Button variant="action" onClick={handleToggle} tabIndex="-1">
      {passwordShown ?
        <Icon id="icon-visibility_off" size="24" />
        :
        <Icon id="icon-visibility" size="24" />
      }
    </Button>
  </>);
});

const InputDate = forwardRef(({ onChange, ...props }, ref) => {
  const now = new Date()
  const [startDate, setStartDate] = useState(now)
  const minDate = now.setDate(now.getDate() + 1)

  const handleChange = (date) => {
    setStartDate(date)

    if (onChange) {
      onChange(date)
    }

    if (ref.current) {
      ref.current.handleCalendarClickOutside()
    }
  }

  return (
    <DatePicker ref={ref}
      showTimeSelect
      timeCaption="Hora"
      dateFormat="dd/MM/yyyy HH:mm:ss"
      closeOnScroll={true}
      selected={startDate}
      locale={ptBr}
      customInput={<Input {...props} />}
      minDate={minDate}
      onChange={handleChange}
    />
  );
});

const InputFile = forwardRef((props, ref) => {
  return (
    <Input ref={ref} {...props} type="file" />
  );
});

const InputSearch = forwardRef((props, ref) => {
  return (
    <Input ref={ref} {...props} type="search" />
  );
});

const InputCreditCard = ({ onChange, ...props }) => {
  const [cardType, setCardType] = useState(null);

  const handleChange = (event) => {
    let cardNumber = event.target.value;
    cardNumber = cardNumber.replace(/\D/g, '');
    cardNumber = cardNumber.replace(/(\d{4})(\d{1,4})?(\d{1,4})?(\d{1,4})?/, '$1 $2 $3 $4');

    const types = creditCardType(cardNumber);

    if (types.length) {
      setCardType(types[0].type);
    } else {
      setCardType(null);
    }

    if (onChange) {
      event.target.value = cardNumber;
      onChange(event);
    }
  };

  return (
      <div className="input-credit-card">
        <Input {...props} type="tel" onChange={handleChange} maxLength={19} />
        {cardType && (
            <Icon id={'icon-' + cardType} className="icon" size="30" />
        )}
      </div>
  );
};

const InputCheckbox = forwardRef(({ children, ...props }, ref) => {
  var newClassNames = classNames('form-check-input', props.className)
  return (
    <input ref={ref} {...props} type="checkbox" className={newClassNames} />
  );
});

const InputRadio = forwardRef(({ children, ...props }, ref) => {
  return (
    <input ref={ref} {...props} type="radio" className="form-check-input" />
  );
});

const InputSwitch = forwardRef(({ children, labelOn, labelOff, ...props }, ref) => {
  const checked = props.defaultChecked || null

  return (
    <label className="control-switch">
      <input ref={ref} {...props} type="checkbox" defaultChecked={checked} />
      {labelOn && labelOff &&
        <span className="control-switch-label" data-label-on={labelOn} data-label-off={labelOff}></span>
      }
      <span className="control-switch-inner">
        <span className="control-switch-bullet"></span>
      </span>
    </label>
  );
});

const InputGroup = (props) => {
  return (
    <div {...props} className="input-group" />
  )
}

const InputVariable = forwardRef(({ variables, onChange, name, ...props }, ref) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [finalValue, setFinalValue] = useState("");

  const handleVariableClick = (variable) => {
    if (!selectedVariables.includes(variable)) {
      setSelectedVariables((prev) => [...prev, variable]);
    }
  };

  const handleRemoveVariable = (variableToRemove) => {
    setSelectedVariables((prev) =>
      prev.filter((variable) => variable !== variableToRemove)
    );
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const calculateFinalValue = () => {
    let value = inputValue;

    selectedVariables.forEach((variable) => {
      value = value + ` <#${variable === 'TemplateEditor' ? 'Preparador do documento' : variable}#>`;
    });

    return value;
  };

  useEffect(() => {
    const updatedValue = calculateFinalValue();
    setFinalValue(updatedValue);

    // Chama o onChange passando o valor final calculado junto com o "name"
    if (onChange) {
      onChange({
        target: {
          name: name, // Incluindo o "name" aqui
          value: updatedValue
        }
      });
    }
  }, [inputValue, selectedVariables]);

  return (
    <div>
      <div className="input-variables-wrapper">
        <Input
          ref={ref}
          {...props}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Digite algo ou adicione variáveis"
        />
        
        <div className="variables-boxes">
          {selectedVariables.map((variable, index) => (
            <span key={index} className="variable-box">
              {`<#${variable}#>`}
              <Button onClick={() => handleRemoveVariable(variable)} variant="remove-icon">
                <Icon id="icon-close" size="14" />
              </Button>
            </span>
          ))}
        </div>
      </div>

      <div className="input-variables">
        <p className="title">Variáveis</p>
        <ul className="list-variables">
          {variables.map((variable) => (
            <li
              key={variable}
              onClick={() => handleVariableClick(variable)}
              className="item-variable"
            >
              {`<#${variable}#>`}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});


Input.Tel = InputTel;
Input.Email = InputEmail;
Input.Phone = InputPhone;
Input.Cpf = InputCpf;
Input.Password = InputPassword;
Input.Date = InputDate;
Input.Search = InputSearch;
Input.CreditCard = InputCreditCard;
Input.File = InputFile;
Input.Checkbox = InputCheckbox;
Input.Radio = InputRadio;
Input.Switch = InputSwitch;
Input.Group = InputGroup;
Input.Variable = InputVariable;

export default Input;
