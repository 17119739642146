import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Api } from '../../api/Assinafy';
import { Page, Section } from '../../layout';
import { Form, Input, Button, Alert } from '../../components';

const SignerVerification = () => {
  const { signerAccessCode } = useParams()
  const navigate = useNavigate()
  const [verificationCode, setVerificationCode] = useState([])
  const [formError, setFormError] = useState([])
  const [formLoading, setFormLoading] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()

    if (verificationCode.length === 6) {
      setFormLoading(true)

      Api('signer/verifyCode')(signerAccessCode, verificationCode.join(''))
        .then((resp) => {
          if (resp.status === 200) {
            navigate(`/sign/${signerAccessCode}`, {
              state: { verified: true }
            })
          }
        })
        .catch(({ response }) => {
          if (response) {
            setFormError({
              type: 'warning',
              message: response.data.message
            })
          }

          setFormLoading(false)
        })
    }
  }

  useEffect(() => {
    const pasteAccessCode = (e) => {
      const accessCode = e.clipboardData.getData('Text')
      const accessCodeSplit = accessCode.replace(/[^\d]/g, '').split('')
      const inputs = document.querySelectorAll('.form-sign-verify input')
      const length = inputs.length
      let i = -1
      while (++i < length) {
        inputs[i].value = accessCodeSplit[i] || ''
        setVerificationCode(prev => {
          prev[i] = inputs[i].value
          return prev;
        })
      }
    }
    document.addEventListener('paste', pasteAccessCode);

    return () => {
      document.removeEventListener('paste', pasteAccessCode)
    }
  }, [signerAccessCode])

  return (
    <Page className="page-sign">
      <Section>
        <Section.Header
          title="Olá, informe o código de verificação"
          opening="Insira o código de verificação enviado por e-mail para acessar e assinar o documento"
        />
        <Form id="form-signer-verification" className="form-sign form-sign-verify"
          onSubmit={handleSubmit}
        >
          {formError.message &&
            <Alert variant={formError.type} onClose={() => setFormError([])}>
              {formError.message}
            </Alert>
          }

          <Form.Group>
            {[0, 1, 2, 3, 4, 5].map((v) => {
              return (
                <Input.Tel key={v} autoFocus={v === 0} data-id={v}
                  maxLength="1"
                  required
                  onChange={e => {
                    const idx = parseInt(e.target.dataset.id)
                    const value = e.target.value

                    setVerificationCode(prev => {
                      const code = [...prev]
                      code[idx] = value
                      return code;
                    })

                    if (idx < 5 && value) {
                      document.querySelector('[data-id="' + (idx + 1) + '"]').focus();
                    } else if (idx > 0 && !value) {
                      document.querySelector('[data-id="' + (idx - 1) + '"]').focus();
                    }
                  }}
                />
              )
            })}
          </Form.Group>

          <div className="form-footer">
            <Button variant="primary" submit size="lg" loading={formLoading}>
              ACESSAR DOCUMENTO
            </Button>
          </div>

          {/*
          <div className="text-center">
            <Button
              variant="link"
              className="btn-request"
              onClick={handleRequestVerify}
              loading={linkLoading}
              loadingText="Reenviando..."
            >
              REENVIAR CÓDIGO DE VERIFICAÇÃO
            </Button>
          </div>
*/}
        </Form>
      </Section>
    </Page>
  )
};

export default SignerVerification;