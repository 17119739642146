import React, { useState, useEffect, useLayoutEffect } from 'react';

import { Router, Routes, Route } from 'react-router-dom';

import DashboardRoute from './routes/DashboardRoute';
import PrepareRoute from './routes/PrepareRoute';
import PrepareRouteTemplate from './routes/PrepareRouteTemplate';
import PrepareRouteTemplateUse from './routes/PrepareRouteTemplateUse';
import PublicRoute from './routes/PublicRoute';
import SignRoute from './routes/SignRoute';

import Login from './modules/Login';
import ForgotPassword from './modules/Login/ForgotPassword';
import ForgotPasswordSuccess from './modules/Login/ForgotPasswordSuccess';
import PasswordReset from './modules/Login/PasswordReset';
import PasswordResetSuccess from './modules/Login/PasswordResetSuccess';
import Signup from './modules/Login/Signup';
import EmailVerification from './modules/EmailVerification';
import SignerVerification from './modules/SignerVerification';
import Plans from './modules/Plans';
import Logout from './modules/Login/Logout';
import Account from './modules/Account';
import ChangePassword from './modules/Account/ChangePassword';
import Dashboard from './modules/Dashboard';
import Documents from './modules/Documents';
import DocumentView from './modules/Document/DocumentView';
import TemplateView from './modules/Template/TemplateView';
import TemplateUse from './modules/Template/TemplateUse';
import DocumentSigners from './modules/Document/DocumentSigners';
import DocumentPrepare from './modules/Document/DocumentPrepare';
import DocumentReview from './modules/Document/DocumentReview';
import TemplateSigners from './modules/Template/TemplateSigners';
import TemplatePrepare from './modules/Template/TemplatePrepare';
import TemplateReview from './modules/Template/TemplateReview';
import TemplateSent from './modules/Template/TemplateSent';
import TemplateReviewDocument from './modules/Template/TemplateReviewDocument';
import DocumentSent from './modules/Document/DocumentSent';
import DocumentSign from './modules/Document/DocumentSign';
import DocumentClosed from './modules/Document/DocumentClosed';
import DocumentSuccess from './modules/Document/DocumentSuccess';
import DocumentsSign from './modules/Documents/DocumentsSign';
import Templates from './modules/Templates/Templates';
import Workspace from './modules/Workspace';
import FieldView from './modules/Fields/FieldView';
import UserView from './modules/Users/UserView';
import Profile from './modules/Profile';
import ProfileDelete from './modules/ProfileDelete';
import Signers from './modules/Signers/Signers';
import SignerView from './modules/Signers/SignerView';

import { browserHistory } from './history';

const App = () => {
  return (
    <HistoryRouter
      history={browserHistory}
    >
      <Routes>
        <Route path="/" element={<PublicRoute />}>
          <Route path="" element={<RouteItem title="Efetuar Login"><Login /></RouteItem>} />
          <Route path="forgot-password/success" element={<RouteItem title="Esqueceu senha"><ForgotPasswordSuccess /></RouteItem>} />
          <Route path="forgot-password" element={<RouteItem title="Esqueceu senha"><ForgotPassword /></RouteItem>} />
          <Route path="password-reset/success" element={<RouteItem title="Resetar senha"><PasswordResetSuccess /></RouteItem>} />
          <Route path="password-reset" element={<RouteItem title="Resetar senha"><PasswordReset /></RouteItem>} />
          <Route path="password-create/success" element={<RouteItem title="Criar senha"><PasswordResetSuccess isNew /></RouteItem>} />
          <Route path="password-create" element={<RouteItem title="Criar senha"><PasswordReset isNew /></RouteItem>} />
          <Route path="signup" element={<RouteItem title="Criar conta"><Signup /></RouteItem>} />
          <Route path="plans" element={<RouteItem title="Planos"><Plans /></RouteItem>} />
          <Route path="email-verification" element={<RouteItem title="Verificar e-mail"><EmailVerification /></RouteItem>} />
          <Route path="verify/:signerAccessCode" element={<RouteItem title="Verificar e-mail"><SignerVerification /></RouteItem>} />
          <Route path="invitation/:invitation_id" element={<RouteItem title="Convite"><Signup /></RouteItem>} />
          <Route path="dashboard/documents/:id/sent" element={<RouteItem title="Enviado : Documento"><DocumentSent /></RouteItem>} />
          <Route path="dashboard/templates/:id/sent" element={<RouteItem title="Criado : Template"><TemplateSent /></RouteItem>} />
          <Route path="dashboard/account/change-password" element={<RouteItem title="Alterar senha"><ChangePassword /></RouteItem>} />
          <Route path="dashboard/profile/delete" element={<RouteItem title="Excluir de conta"><ProfileDelete /></RouteItem>} />
          <Route path="dashboard/profile/delete-scheduled" element={<RouteItem title="Agendado : Excluir de conta"><ProfileDelete scheduled={true} /></RouteItem>} />
          <Route path="logout" element={<RouteItem title="Sair"><Logout /></RouteItem>} />
        </Route>
        <Route path="/dashboard" element={<DashboardRoute />}>
          <Route path="" element={<RouteItem title="Dashboard"><Dashboard /></RouteItem>} />
          <Route path="documents" element={<RouteItem title="Documentos"><Documents /></RouteItem>} />
          <Route path="documents/:id/view" element={<RouteItem title="Visualizar : Documento"><DocumentView /></RouteItem>} />
          <Route path="templates" element={<RouteItem title="Templates"><Templates /></RouteItem>} />
          <Route path="templates/:id/view" element={<RouteItem title="Visualizar : Template"><TemplateView /></RouteItem>} />
          <Route path="signers" element={<RouteItem title="Seus contatos"><Signers /></RouteItem>} />
          <Route path="signers/:id/view" element={<RouteItem title="Visualizar contato"><SignerView isRead /></RouteItem>} />
          <Route path="signers/:id/edit" element={<RouteItem title="Editar contato"><SignerView /></RouteItem>} />
          <Route path="account/:tab" element={<RouteItem title="Conta"><Account /></RouteItem>} />
          <Route path="account" element={<RouteItem title="Minha Conta"><Account /></RouteItem>} />
          <Route path="profile" element={<RouteItem title="Editar minha conta"><Profile /></RouteItem>} />
          <Route path="workspace/:tab" element={<RouteItem title="Personalizar workspace"><Workspace /></RouteItem>} />
          <Route path="workspace" element={<RouteItem title="Personalizar workspace"><Workspace /></RouteItem>} />
          <Route path="fields/new" element={<RouteItem title="Novo campo"><FieldView /></RouteItem>} />
          <Route path="fields/:id/view" element={<RouteItem title="Campo"><FieldView isRead /></RouteItem>} />
          <Route path="fields/:id/edit" element={<RouteItem title="Editar campo"><FieldView /></RouteItem>} />
          <Route path="users/:id/view" element={<RouteItem title="Usuário"><UserView isRead /></RouteItem>} />
          <Route path="users/:id/edit" element={<RouteItem title="Editar usuário"><UserView /></RouteItem>} />
        </Route>
        <Route path="/dashboard/documents/:id" element={<PrepareRoute />}>
          <Route path="signers" element={<RouteItem title="Signatários : Documento"><DocumentSigners /></RouteItem>} />
          <Route path="prepare" element={<RouteItem title="Preparação : Documento"><DocumentPrepare /></RouteItem>} />
          <Route path="review" element={<RouteItem title="Revisar e enviar : Documento"><DocumentReview /></RouteItem>} />
        </Route>
        <Route path="/dashboard/documents/:id/virtual" element={<PrepareRoute virtual />}>
          <Route path="signers" element={<RouteItem title="Signatários : Documento"><DocumentSigners virtual /></RouteItem>} />
          <Route path="prepare" element={<RouteItem title="Preparação : Documento"><DocumentPrepare virtual /></RouteItem>} />
          <Route path="review" element={<RouteItem title="Revisar e enviar : Documento"><DocumentReview virtual /></RouteItem>} />
        </Route>
        <Route path="/dashboard/templates/:id" element={<PrepareRouteTemplate />}>
          <Route path="signers" element={<RouteItem title="Signatários : Template"><TemplateSigners /></RouteItem>} />
          <Route path="prepare" element={<RouteItem title="Preparação : Template"><TemplatePrepare /></RouteItem>} />
          <Route path="review" element={<RouteItem title="Revisão e Conclusão : Template"><TemplateReview /></RouteItem>} />
          {/* <Route path="use" element={<RouteItem title="Utilizar : Template"><TemplateUse /></RouteItem>} /> */}
        </Route>
        <Route path="/dashboard/templates/:id" element={<PrepareRouteTemplateUse />}>
          <Route path="use" element={<RouteItem title="Utilizar : Template"><TemplateUse /></RouteItem>} />
          <Route path="review/document" element={<RouteItem title="Revisar e Conclusão : Template"><TemplateReviewDocument /></RouteItem>} />
        </Route>
        <Route path="/dashboard/templates/:id/virtual" element={<PrepareRouteTemplate virtual />}>
          <Route path="signers" element={<RouteItem title="Signatários : Template"><TemplateSigners virtual /></RouteItem>} />
          <Route path="prepare" element={<RouteItem title="Preparação : Template"><TemplatePrepare virtual /></RouteItem>} />
          <Route path="review" element={<RouteItem title="Revisão e Conclusão : Template"><TemplateReview virtual /></RouteItem>} />
        </Route>
        <Route path="/sign" element={<SignRoute />}>
          <Route path=":signerAccessCode" element={<RouteItem title="Assinatura : Documento"><DocumentSign /></RouteItem>} />
          <Route path=":signerAccessCode/success" element={<RouteItem title="Assinatura : Documento"><DocumentSuccess /></RouteItem>} />
          <Route path=":signerAccessCode/closed" element={<RouteItem title="Assinatura : Documento"><DocumentClosed /></RouteItem>} />
          <Route path=":signerAccessCode/documents" element={<RouteItem title="Assinatura : Documentos aguardando assinatura"><DocumentsSign /></RouteItem>} />
        </Route>
      </Routes>
    </HistoryRouter>
  );
};

const HistoryRouter = ({
  basename,
  children,
  history
}) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => {
    history.listen(setState)
  }, [history])

  return <Router
    basename={basename}
    children={children}
    location={state.location}
    navigationType={state.action}
    navigator={history}
  />
}

const RouteItem = ({ title, children }) => {
  useEffect(() => {
    const completePageTitle = `${title ? `${title} | ` : ''}Assinafy`

    document.title = completePageTitle

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'VirtualPageview',
      // virtualPageURL: window.location.href + window.location.search,
      virtualPageTitle: completePageTitle
    })
  }, [title])

  return <>{children}</>
}

export default App;
