import React, { useState, useEffect } from 'react';
import { Api, getSession } from '../../api/Assinafy';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Form, Alert, Button, Input, Icon } from '../../components';
import { Page, Container } from '../../layout';

import './styles.scss';

const EmailVerification = () => {
  const location = useLocation()
  const { search } = location
  const [verificationCode, setVerificationCode] = useState([])
  const [formLoading, setFormLoading] = useState(false)
  const [linkLoading, setLinkLoading] = useState(false)
  const [formError, setFormError] = useState({})
  const navigate = useNavigate()
  const query = new URLSearchParams(search)
  const token = query.get('token')
  const { user } = getSession()
  const stateAlertSuccess = {
    state: {
      alert: { type: 'success', message: 'Sua conta foi verificada com sucesso' }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (verificationCode.length === 6) {
      setFormLoading(true)

      Api('user/verifyEmail')({
        email: user.email,
        code: verificationCode.join('')
      })
        .then(resp => {
          if (resp.status === 200) {
            navigate('/dashboard', stateAlertSuccess)
          }
        })
        .catch(({ response }) => {
          if (response) {
            setFormError({
              type: 'warning',
              message: response.data.message
            })
          }

          setFormLoading(false)
        })
    }
  }

  const handleRequestVerify = (event) => {
    event.preventDefault()

    setLinkLoading(true)

    Api('user/requestEmailVerification')({ email: user.email })
      .then(resp => {
        setFormError({
          type: 'info',
          message: 'O e-mail com código de ativação foi reenviado'
        })
        setLinkLoading(false)
      })
      .catch(({ response }) => {
        if (response) {
          setFormError({
            type: 'warning',
            message: response.data.message
          })
        }

        setLinkLoading(false)
      })
  }

  useEffect(() => {
    if (!user) {
      navigate('/')
    }

    if (user && user.is_email_verified) {
      navigate('/dashboard')
    }

    if (token) {
      Api('user/verifyEmail')({ token: token })
        .then(resp => {
          if (resp.status === 200) {
            navigate('/dashboard', stateAlertSuccess)
          }
        })
        .catch(({ response }) => {
          if (response) {
            navigate('/dashboard', {
              alert: {
                type: 'warning',
                message: response.data.message
              }
            })
          }
        })
    }
  }, [user, token])

  useEffect(() => {
    const pasteAccessCode = (e) => {
      const accessCode = e.clipboardData.getData('Text')
      const accessCodeSplit = accessCode.replace(/[^\d]/g, '').split('')
      const inputs = document.querySelectorAll('.form-sign-verify input')
      const length = inputs.length
      let i = -1
      while (++i < length) {
        inputs[i].value = accessCodeSplit[i] || ''
        setVerificationCode(prev => {
          prev[i] = inputs[i].value
          return prev;
        })
      }
    }
    document.addEventListener('paste', pasteAccessCode);

    return () => {
      document.removeEventListener('paste', pasteAccessCode)
    }
  }, [verificationCode])

  if (token) {
    return (<>
      <Page>
        <Container>
          Aguarde, estamos verificando sua conta...
        </Container>
      </Page>
    </>)
  }

  return (
    <Page className="page-sign"
      title="Confirmação do e-mail"
      opening={<>
        Enviamos um e-mail para <a href={`mailto:${user?.email}`}>{user?.email}</a> com um código de ativação e um link.
        <br /><br />
        Informe o código recebido abaixo ou clique no link do e-mail para ativar sua conta.
      </>}
    >
      <Form id="form-email-verification" className="form-sign form-sign-verify"
        onSubmit={handleSubmit}
      >
        {formError.message &&
          <Alert variant={formError.type} onClose={() => setFormError([])}>
            {formError.message}
          </Alert>
        }

        <h2 className="h3">
          Informe o código de ativação
        </h2>

        <Form.Group>
          {[0, 1, 2, 3, 4, 5].map((v) => {
            return (
              <Input.Tel key={v} autoFocus={v === 0} data-id={v}
                maxLength="1"
                required
                onChange={e => {
                  const idx = parseInt(e.target.dataset.id)
                  const value = e.target.value

                  setVerificationCode(prev => {
                    const code = [...prev]
                    code[idx] = value
                    return code;
                  })

                  if (idx < 5 && value) {
                    document.querySelector('[data-id="' + (idx + 1) + '"]').focus();
                  } else if (idx > 0 && !value) {
                    document.querySelector('[data-id="' + (idx - 1) + '"]').focus();
                  }
                }}
              />
            )
          })}
        </Form.Group>

        <Form.Footer horizontal>
          <Button variant="primary" submit loading={formLoading}>
            Ativar agora
          </Button>
          <Link to={'/dashboard'} className="btn btn-info">
            Ativar depois
          </Link>
        </Form.Footer>

        <div className="form-help">
          Não recebi o código. <Button
            variant="link"
            className="btn-request"
            onClick={handleRequestVerify}
            loading={linkLoading}
            loadingText="Reenviando..."
          >
            Reenviar
          </Button>
        </div>
      </Form>
    </Page>
  );
};

export default EmailVerification;