import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Api, urlWithAccessToken, getSession, cancelRequest } from '../../api/Assinafy';
import { signerColors } from '../../utils/SignersTemplate';
import { usePrepareContext } from '../../routes/PrepareRoute';
import { PrepareNavbar } from '../../layout/Prepare';
import { Dashboard, Scroll, Section } from '../../layout';
import ModalViewer from './View/ModalViewer';
import {
	Form,
	Textarea,
	Button,
	Icon,
	Input,
	Alert,
	Badge,
	Image
} from '../../components';
import SignerDocTemplate from '../../components/SignerDocTemplate';
import { signerEmpty } from '../../utils/SignersTemplate';
import { v4 as uuid } from 'uuid';
import thumbDocument from '../../assets/images/thumb-document.svg';
import { getFieldProps } from '../../utils/Fields';

const TemplateUse = ({ virtual }) => {
	const [
		documentContext,
		setDocumentContext,
		setSignersContext,
	] = usePrepareContext();
	const [loading, setLoading] = useState(false);
	const [formError, setFormError] = useState([]);
	const [formData, setFormData] = useState({});
	const [showExpiration, setShowExpiration] = useState(false);
	const { id: documentId } = useParams();
	const navigate = useNavigate();
	const accountId = getSession().accounts[0].id
	const cancelToken = cancelRequest();
	const datePickerRef = useRef();
	const [newSigners, setNewSigners] = useState([signerEmpty()]);
	const [formValidation, setFormValidation] = React.useState([]);
	const [modalViewer, setModalViewer] = useState(false)
	const [editorRoleFields, setEditorRoleFields] = useState([])

	const addSignersContext = ({ id, name, assignment_type }) => {
		setSignersContext((prevState) => {
			if (prevState.find((signer) => signer.name === name)) {
				return prevState;
			}

			return [
				...prevState,
				{
					uuid: uuid(),
					id,
					name,
					assignment_type
				}
			];
		});
	};

	const updateNewSigner = (updatedSigner, uuid) => {
		setNewSigners((prevSigners) =>
			prevSigners.map((signer) =>
			signer.uuid === uuid ? { ...signer, ...updatedSigner } : signer
			)
		);
	};

	const removeNewSigner = (uuid) => {
		const newNewSigners = newSigners.filter(
			(signer) => signer.uuid !== uuid
		);
		if (!newNewSigners.length) {
			newNewSigners.push(signerEmpty());
		}
		setNewSigners(newNewSigners);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
	
		const editorFields = formData.editor_fields || [];
	
		const data = {
			name: formData.name || documentContext.name,
			message: formData.message || documentContext.message,
			editor_fields: editorFields, 
			...formData,
			expires_at: formData.expires_at || null
		};
	
		setDocumentContext(data);
		navigate(`/dashboard/templates/${documentId}/review/document`);
	};

	const fieldValueChange = (field_id, value) => {
		setFormData((prev) => {
			const field = editorRoleFields.find(field => field.field_id === field_id);

			if (field) {
				const fieldLabel = field.label || "";

				const updatedEditorFields = [
					...(prev.editor_fields || []).filter(field => field.field_id !== field_id),
					{ field_id, value, label: fieldLabel }
				];

				return {
					...prev,
					editor_fields: updatedEditorFields
				};
			}

			return {
				...prev,
				[field_id]: value
			};
		});
	};
	
	const handleOpenClick = () => {
		setShowExpiration(true);
	};

	const handleCloseClick = () => {
		setShowExpiration(false);
	};

	const showInputError = (inputName) => {
		const error = formValidation.find((error) => error.uuid === newSigners.uuid && error.name === inputName)
		if (error) {
		  return (<div className="help-block">
			{error.message}
		  </div>)
		}
	}

	const fetchFieldType = async (fieldId) => {
		try {
			const response = await Api('field/view')(accountId, fieldId, {
				...cancelToken.config,
			})
			if (response.status === 200) {
				return response.data.data.type;
			}
		} catch (error) {
			console.error('Erro ao buscar o tipo do campo:', error);
		}
		return null;
	};

	useEffect(() => {
		Api('template/view')(documentId, {
			...cancelToken.config
		})
			.then(async ({ status, data }) => {
				if (status === 200) {
					window.pageCanvas = [];
					const editorRole = data.data.roles.find(
						(role) => role.assignment_type === "Editor"
					);
	
					let fields = [];
	
					data.data.pages.forEach((page) => {
						const editorField = page.fields.filter(
							(field) => field.role_id === editorRole.id
						);
	
						if (editorField.length > 0) {
							fields.push(...editorField);
						}
					});
	
					const updatedFields = await Promise.all(fields.map(async (field) => {
						const type = await fetchFieldType(field.field_id);
						return {
							...field,
							type: type
						};

					}));
	
					if (updatedFields.length > 0) {
						setEditorRoleFields(updatedFields);
					}
	
					const signersFromAPI = data.data.roles
						.filter((signer) => signer.assignment_type !== 'Editor')
						.map((signer) => ({
							...signer,
							uuid: signer.uuid || uuid()
						}));
	
					setDocumentContext(data.data);
					setNewSigners(signersFromAPI || []);
				}
			})
			.finally(() => {
				setNewSigners((prevSigners) => {
					if (!prevSigners.length) {
						return [signerEmpty()];
					}
					return prevSigners;
				});
			});
	
		return () => {
			cancelToken.cancel();
		};
	}, [documentId, setDocumentContext]);
	

	return (
		<Form
			id="form-document-review"
			className="form-prepare"
			onSubmit={handleSubmit}
		>
			<PrepareNavbar
				document={documentContext}
				step="signers"
				page="use"
				submitLoading={loading}
				text="Revisão e conclusão"
				url="templates"
				textButtonSubmit="CONTINUAR"
			/>
			<Scroll>
				<Dashboard.Content>
					<Row>
						<Col md={{ span: 8, offset: 2 }}>
							<Dashboard.Header 
							  title={<>
									{documentContext.name}
									<Badge>
										<Icon id="icon-template" size="14" color="#1452FF" />
										Template
									</Badge>
								</>}
							 />

						<Section className="section-document">
							<Row>
								<Col lg={{ span: 'auto', order: 'first' }}>
								<Image key={uuid()}
									width="224"
									height="104"
									src={document.artifacts?.thumbnail && urlWithAccessToken(document.artifacts.thumbnail)}
									placeholder={thumbDocument}
								>
									<Button variant="info"
									onClick={() => setModalViewer(true)}
									>
									<Icon id="icon-visibility" className="me-1" size="18" />
									VISUALIZAR TEMPLATE
									</Button>
								</Image>
								</Col>
							</Row>
							</Section>

							<Alert
								show={formError.length}
								variant="warning"
								onClose={() => setFormError([])}
							>
								{formError.map((error) => error)}
							</Alert>

							<Section className="section-document">
								<Section.Header
									title="Quem vai assinar ou receber o documento?"
									opening={`Informe agora quem serão as pessoas que terão algum tipo de acesso ou papéis neste documento.`}
								/>

								{newSigners.map((signer, i) => {
									return (
										<SignerDocTemplate.Add
											key={signer.uuid}
											{...signer}
											signers={newSigners}
											onUpdate={updateNewSigner}
											onRemove={removeNewSigner}
											formValidation={formValidation}
											setFormValidation={
												setFormValidation
											}
											formData={formData}
											setFormData={setFormData}
											role={signer}
											bgColor={signerColors[i].color}
											uuid={signer.uuid}
										/>
									);
								})}
							</Section>

							{
								editorRoleFields.length > 0 &&
								<Section>
									<Section.Header
										title="Informações antes do envio"
										opening="É necessário preencher os campos abaixo antes de enviar o documento"
									/>
									{
										editorRoleFields.map((field, i) => {
											const { type, mask, maxLength, pattern } = getFieldProps(field.type);

											return (
												<Form.Group key={i}>
													<Input
														size="lg"
														name={field.field_id}
														required
														label={field.label}
														minLength="5"
														data-uuid={field.field_id}
														onChange={(event) => {
															const value = mask ? mask(event) : event.target.value;
															event.target.value = value;
															fieldValueChange(field.field_id, event.target.value);
														}}
														type={type}
														maxLength={maxLength}
														pattern={pattern}
													/>
													{showInputError('email')}
												</Form.Group>
											);
										})
									}

								</Section>
							}

							<Section>
								<Section.Header
									title="Título do documento e mensagem"
									opening="Esta mensagem será enviada a todos que vão receber o documento, e pode ser personalizada a cada utilização deste template"
								/>
								<Form.Group>
									<Input
										size="lg"
										name="name"
										label="Nome do documento"
										onChange={(event) =>
											fieldValueChange(
												event.target.name,
												event.target.value
											)
										}
									/>
								</Form.Group>
								<Form.Group>
									<Textarea
										name="message"
										defaultValue={documentContext.message}
										onChange={(event) =>
											fieldValueChange(
												event.target.name,
												event.target.value
											)
										}
										label="Mensagem"
									/>
								</Form.Group>
							</Section>

							<Section>
								<Section.Header
									title="Validade do documento"
									opening="Deseja colocar uma data de validade para esse documento?"
								/>
								{!showExpiration ? (
									<Button
										variant="link"
										onClick={handleOpenClick}
									>
										<Icon
											id="icon-date"
											className="me-1"
											size="18"
										/>
										DEFINIR DATA
									</Button>
								) : (
									<Form.Group className="group-calendar">
										<Input.Group>
											<Input.Date
												ref={datePickerRef}
												name="expires_at"
												className="control-datepicker"
												onChange={(value) =>
													fieldValueChange(
														'expires_at',
														value
													)
												}
												label="Data"
											/>
											<Button
												variant="icon"
												onClick={handleCloseClick}
											>
												<Icon
													id="icon-close"
													size="24"
												/>
											</Button>
										</Input.Group>
									</Form.Group>
								)}
							</Section>
						</Col>
					</Row>
				</Dashboard.Content>
			</Scroll>
			<ModalViewer show={!!modalViewer}
				document={documentContext}
				onClose={() => setModalViewer(false)}
			/>
		</Form>
	);
};

export default TemplateUse;
