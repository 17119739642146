import React, {useEffect, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Page, Section } from '../../layout';
import { Button, Icon } from '../../components';
import { Api, cancelRequest } from '../../api/Assinafy';

const DocumentSent = () => {
  const location = useLocation()
  const { state } = location
  const [documentContext, setDocumentContext] = useState({});
  const [loading, setLoading] = useState(true); 
  const cancelToken = cancelRequest();

  useEffect(() => {
    let isMounted = true; 
    let intervalId;

    const fetchDocumentStatus = () => {
      Api('document/view')(state?.document_id)
        .then(({ status, data }) => {
          if (isMounted && status === 200) {
            setDocumentContext(data.data);

            if (data.data.status === "pending_signature") {
              setLoading(false);
              clearInterval(intervalId);
            }
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar documento:", error);
        });
    };

    fetchDocumentStatus(); 
    intervalId = setInterval(fetchDocumentStatus, 3000);

    return () => {
      isMounted = false; 
      clearInterval(intervalId); 
      cancelToken.cancel();
    };
  }, []); 

  return (
    <Page className="page-document-sent">
      <Section>
        <Icon id="icon-template_check" size="188" className="mb-4" />
        <h1 className="h1">
          Documento enviado com sucesso!
        </h1>
        <Section.Opening>
          <b>{state?.name}</b> foi enviado para assinatura, você pode acompanhar as assinaturas deste documento a qualquer momento.
        </Section.Opening>
        <Button as={Link} to={'/dashboard'} variant="primary" loading={loading} disabled={loading}>
         <Icon id="icon-leaderboard" size="18" />
          IR PARA O INÍCIO
        </Button>
      </Section>
    </Page>
  )
}

export default DocumentSent;