import React, { forwardRef, useImperativeHandle, useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../';
import classNames from 'classnames';

import './styles.scss';

const Dropdown = forwardRef(({ button, variant, align, ...props }, ref) => {
  const [menuShown, setMenuShown] = useState(false)
  const { className, children } = props
  const prefix = 'dropdown';
  const newClassName = classNames(prefix, className)
  const dropdownRef = useRef()

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current !== event.target.closest(`.${prefix}`) ||
      event.target.classList.contains('dropdown-item')
    ) {
      setMenuShown(false)
    }
  };

  const handleToggle = useCallback(() => {
    setMenuShown(prevState => !prevState)
  });

  useImperativeHandle(ref, () => ({
    close: () => setMenuShown(false)
  }))

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    }
  }, [])

  useEffect(() => {
    const dropdownClientRect = dropdownRef.current.getBoundingClientRect()

    if (menuShown) {
      dropdownRef.current.classList.add('show')
    } else {
      dropdownRef.current.classList.remove('show')
    }

    if (dropdownClientRect.y > (window.innerHeight * 0.55)) {
      dropdownRef.current.classList.add('dropup')
    } else {
      dropdownRef.current.classList.remove('dropup')
    }
  }, [menuShown])

  return (
    <div {...props} ref={dropdownRef} className={newClassName}>
      <Button variant={variant} className="dropdown-toggle" onClick={handleToggle}>
        {button}
      </Button>
      <DropdownMenu align={align}>
        {children}
      </DropdownMenu>
    </div>
  );
});

const DropdownMenu = ({ align, ...props }) => {
  const prefix = 'dropdown';
  const newClassName = classNames(`${prefix}-menu`, align === 'right' && `${prefix}-menu-end`)

  return (
    <div {...props} className={newClassName} />
  )
}

const DropdownItem = ({ as: Component = Link, ...props }) => {
  const { className } = props
  let type;
  
  if (Component === 'button') {
    type = 'button';
  } else if (Component === 'div') {
    type = 'div';
  }
  const newClassName = type === 'div'? classNames('dropdown-title', className) : classNames('dropdown-item', className)
  
  return (
    <Component
      {...props}
      className={newClassName}
      type={type}
    />
  );
};

Dropdown.Menu = DropdownMenu;
Dropdown.Item = DropdownItem;

export default Dropdown;
