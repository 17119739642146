import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Api } from '../../api/Assinafy';
import { signerColors } from '../../utils/Signers';
import { usePrepareContext } from '../../routes/PrepareRoute';
import { PrepareNavbar } from '../../layout/Prepare';
import { Dashboard, Scroll, Section } from '../../layout';
import { Form, Table, Avatar, Icon, Alert, TextField } from '../../components';
import { dateFormat, timeFormat } from '../../utils/Formats'
import './styles.scss'

const TemplateReviewDocument = ({ virtual }) => {
	const [
		documentContext
	] = usePrepareContext();
	const [loading, setLoading] = useState(false);
	const [formError, setFormError] = useState([]);
	const [formData, setFormData] = useState({});
	const { id: documentId } = useParams();
	const navigate = useNavigate();

	const handleSubmit = (event) => {
		event.preventDefault();
	
		setLoading(true);
	
		if (event.target.checkValidity()) {
			const signersData = documentContext.signers.map((signer) => ({
				role_id: signer.role_id,
				email: signer.email,
				name: signer.name
			}));
	
			const editorFieldsData = documentContext.editor_fields.map((field) => ({
				field_id: field.field_id,
				value: field.value
			}));
	
			const data = {
				...documentContext,
				signers: signersData,
				editor_fields: editorFieldsData
			};
	
			Api('document/fromTemplate')(documentId, data)
				.then(({ status, data: response}) => {
					if (status === 200) {
						console.log("Resposta completa:", response.data);
						navigate(`/dashboard/documents/${documentId}/sent`, {
							state: {
								name: documentContext.name,
								id: documentId,
								document_id: response.data.id
							}
						});
					}
				})
				.catch(({ response }) => {
					console.log("Erro na API:", response);
					if (response) {
						setFormError([response.data.message]);
					}
					setLoading(false);
				});
		}
	};
	
	  

	const fieldValueChange = (name, value) => {
		setFormData((prevFormData) => ({
		  ...prevFormData,
		  [name]: value
		}));
	};
	  
	return (
		<Form
			id="form-document-review"
			className="form-prepare"
			onSubmit={handleSubmit}
		>
			<PrepareNavbar
				document={documentContext}
				step="review"
        		page="use"
				submitLoading={loading}
				text="Revisar e enviar"
				url="templates"
				textButtonSubmit="ENVIAR"
			/>
			<Scroll>
				<Dashboard.Content>
					<Row>
						<Col md={{ span: 8, offset: 2 }}>
							<Dashboard.Header title="Revisar e enviar" />

							<Alert
								show={formError.length}
								variant="warning"
								onClose={() => setFormError([])}
							>
								{formError.map((error) => error)}
							</Alert>

							<SectionSigners signers={documentContext.signers} />

							{
								documentContext.editor_fields && documentContext.editor_fields.length > 0 &&
								<Section>
									<Section.Header
										title="Informações antes do envio"
										opening="Confirme a veracidade das informações"
									/>
									<div className="d-flex gap-3 flex-column">
										{
											documentContext.editor_fields.map((field) => (
												<TextField
													key={field.field_id}
													title={field.label}
													value={field.value}
												/>
											))
										}
									</div>
								</Section>
							}

							<Section>
								<Section.Header
									title="Título do documento e mensagem"
								/>
								<div className="d-flex gap-3 flex-column">
									<TextField
										title="Nome do documento"
										value={documentContext.name}
									/>
									<TextField
										title="Mensagem"
										value={documentContext.message? documentContext.message : 'Sem mensagem'}
									/>
								</div>
							</Section>

							<Section>
								<Section.Header
									title="Validade do documento"
									opening={documentContext.expires_at ? `${dateFormat(documentContext.expires_at)} às ${timeFormat(documentContext.expires_at)}` : 'Sem data de validade'}
								/>
							</Section>
						</Col>
					</Row>
				</Dashboard.Content>
			</Scroll>
		</Form>
	);
};

const SectionSigners = ({ signers }) => {
	return (
		<Section>
			<Section.Header
				title="Assinantes"
				opening="Verifique se você associou todos os participantes ao documento"
			/>
			<Table
				rows={signers}
				renderRow={(signer, i) => (
					<tr key={i}>
						<td>
							<div className="signer-header">
								<Avatar
									placeholder={
										<Icon
											id="icon-initial"
											size="20"
										/>
									}
									bgColor={signerColors[i].color}
								/>
								<div className="signer-title">
									{signer.name_role} 
								</div>
								{signer.assignment_type === "Signer" ? "Assina o documento" : "Recebe uma cópia"}
							</div>
						</td>
						<td>{signer.name}</td>
						<td>{signer.email}</td>
					</tr>
				)}
			/>
		</Section>
	);
};

export default TemplateReviewDocument;
