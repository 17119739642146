import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Api, cancelRequest } from '../../api/Assinafy';
import { signerColors } from '../../utils/Signers';
import { usePrepareContext } from '../../routes/PrepareRoute';
import { PrepareNavbar } from '../../layout/Prepare';
import { Dashboard, Scroll, Section } from '../../layout';
import { Form, Table, Avatar, Textarea, Icon, Input, Alert } from '../../components';

const TemplateReview = ({ virtual }) => {
	const [
		documentContext,
		setDocumentContext,
		signersContext,
		,
		fieldsContext
	] = usePrepareContext();
	const [loading, setLoading] = useState(false);
	const [formError, setFormError] = useState([]);
	const [formData, setFormData] = useState({});
	const [showExpiration, setShowExpiration] = useState(false);
	const { id: documentId } = useParams();
	const navigate = useNavigate();
	const cancelToken = cancelRequest();

	const handleSubmit = (event) => {
		event.preventDefault();
	
		setLoading(true);
	
		if (!formData.name) {
			formData.name = documentContext.name;
		}
	
		const updateFieldsData = {
			pages: fieldsContext,
		};
	
		const requests = [
			Api('template/update')(documentId, {
				...formData,
			}),
			Api('template/updateFields')(documentId, updateFieldsData),
		];
	
		Promise.all(requests)
			.then((responses) => {
				const [updateResponse, updateFieldsResponse] = responses;
	
				if (updateResponse.status === 200 && updateFieldsResponse.status === 200) {
					navigate(`/dashboard/templates/${documentId}/sent`, {
						state: {
							name: formData.name,
							id: documentId,
						},
					});
				} else {
					setFormError(['Erro ao atualizar o template ou os campos.']);
					setLoading(false);
				}
			})
			.catch((error) => {
				if (error.response) {
					setFormError([error.response.data.message]);
				} else {
					setFormError(['Erro ao processar a requisição.']);
				}
				setLoading(false);
			});
	};
	  

	const fieldValueChange = (name, value) => {
		setFormData((prevFormData) => ({
		  ...prevFormData,
		  [name]: value
		}));
	};
	  

	const handleOpenClick = () => {
		setShowExpiration(true);
	};

	const handleCloseClick = () => {
		setShowExpiration(false);
	};

	useEffect(() => {
		Api('template/view')(documentId, {
			...cancelToken.config
		}).then(({ status, data }) => {
			if (status === 200) {
				window.pageCanvas = [];
				setDocumentContext(data.data);
			}
		});

		return () => {
			cancelToken.cancel();
		};
	}, []);

	return (
		<Form
			id="form-document-review"
			className="form-prepare"
			onSubmit={handleSubmit}
		>
			<PrepareNavbar
				document={documentContext}
				step="review"
				submitLoading={loading}
				text="Revisão e conclusão"
				url="templates"
				textButtonSubmit="SALVAR TEMPLATE"
			/>
			<Scroll>
				<Dashboard.Content>
					<Row>
						<Col md={{ span: 8, offset: 2 }}>
							<Dashboard.Header title="Revisão e conclusão" />

							<Alert
								show={formError.length}
								variant="warning"
								onClose={() => setFormError([])}
							>
								{formError.map((error) => error)}
							</Alert>

							<Section>
								<Section.Header
									title="Nome do template"
									opening="Você vai poder personalizar o nome a cada envio"
								/>
								<Form.Group>
								<Input.Variable
									size="lg"
									name="name"
									label="Nome do template"
									value={formData.name || documentContext.name}	
									variables={signersContext.map((signer) => signer.name)}
									onChange={(event) =>
										fieldValueChange(
											event.target.name,
											event.target.value
										)
									}
									/>

								</Form.Group>
							</Section>


							<SectionSigners signers={signersContext} />

							<Section>
								<Section.Header
									title="Mensagem"
									opening="Esta mensagem será enviada a todos que vão receber o documento, e pode ser personalizada a cada utilização deste template"
								/>
								<Form.Group>
									<Textarea
										name="message"
										onChange={(event) =>
											fieldValueChange(
												event.target.name,
												event.target.value
											)
										}
										label="Mensagem"
									/>
								</Form.Group>
							</Section>
						</Col>
					</Row>
				</Dashboard.Content>
			</Scroll>
		</Form>
	);
};

const SectionSigners = ({ signers }) => {
	return (
		<Section>
			<Section.Header
				title="Signatários"
				opening="Verifique se você associou todos os papéis de quem vai assinar ou receber uma cópia do documento"
			/>
			<Table
				rows={signers}
				renderRow={(signer, i) => (
					<tr key={i}>
						<td>
							<div className="signer-header">
								<Avatar
									placeholder={
										<Icon
											id={signer.assignment_type === 'Signer' ? 'icon-initial' : 'icon-file_copy'}
											size="20"
										/>
									}
									bgColor={signerColors[i].color}
								/>
								{signer.name === 'TemplateEditor' ? 'Preparador do documento' : signer.name}
								{signer.assignment_type && (
									<span className="ms-1 text-muted">
										{signer.assignment_type === 'Signer'
											? 'Assina o documento'
											: 'Recebe uma cópia'}
									</span>
								)}
							</div>
						</td>
						<td>{signer.email}</td>
					</tr>
				)}
			/>
		</Section>
	);
};

export default TemplateReview;
