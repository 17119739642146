import React from 'react';

import './styles.scss';

const TextField = ({ ...props }) => {

  return (
    <div className="text-field">
      <p className="text-field-title">{props.title}</p>
      <p className="text-field-value">{props.value}</p>
    </div>
  );
};

export default TextField;