import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { Api, cancelRequest } from '../../api/Assinafy'
import { Dashboard } from '../../layout'
import { Alert, Toast, Form, Input, Button, EmptyList, Table, Icon, Avatar, Pagination, Dropdown, Modal } from '../../components'

import './styles.scss';

const Signers = () => {
  const fetchSignerDocuments = Api('signer/documents')
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')
  const [signers, setSigners] = useState()
  const [errors, setErrors] = useState([])
  const [formData, setFormData] = useState({})
  const [modalAdd, setModalAdd] = useState(false)
  const [modalRemove, setModalRemove] = useState(false)
  const [modalError, setModalError] = useState(false)
  const [pagination, setPagination] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [removeLoading, setRemoveLoading] = useState(false)
  const [toast, setToast] = useState('')
  const cancelToken = cancelRequest()
  const navigate = useNavigate()
  const { state } = useLocation()

  const handleFieldValueChange = (event) => setFormData({
    ...formData,
    [event.target.name]: event.target.value
  })

  const handleSubmit = (event) => {
    event.preventDefault()

    setSearchParams(formData)
  }

  const handleEdit = (id) => {
    setModalRemove(false)
    navigate(`/dashboard/signers/${id}/edit`)
  }

  const handleRemove = (id) => {
    setRemoveLoading(true);

    Api('signer/delete')(id)
      .then(({ status, data: { data } }) => {
        setRemoveLoading(false)
        handleFetchSigners()
        setToast('Contato removido com sucesso')
        setModalRemove(false)
      })
      .catch(({ response: { data: { message } } }) => {
        setRemoveLoading(false)
        setModalRemove(false)
        setModalError(message)
      })
  }

  const handleFetchSigners = () => {
    Api('signer/index')({
      ...cancelToken.config,
      params: {
        'page': page || 1,
        'per-page': 8,
        ...searchParamsToObject(searchParams.entries())
      }
    })
      .then(({ status, headers, data: { data } }) => {
        if (status === 200) {
          setSigners(data)
          setPageLoaded(true)
        }

        setPagination({
          pageCount: headers['x-pagination-page-count'],
          perPage: headers['x-pagination-per-page'],
          totalCount: headers['x-pagination-total-count']
        })
      })
      .catch(({ response }) => {
        if (response) {
          setErrors([response.data.message])
        }
      })
  }

  const searchParamsToObject = (entries) => {
    const result = {}

    for (const [key, value] of entries) {
      if (key === 'page') continue;

      result[key] = value
    }

    return result
  }

  const signerDocumentsMessage = (total) => {
    const addS = total > 1 ? 's' : ''
    return `${total || 'nenhum'} documento${addS} associado${addS}`
  }

  useEffect(() => {
    setPageLoaded(false)
    setFormData(searchParamsToObject(searchParams.entries()))

    handleFetchSigners()

    return () => {
      cancelToken.cancel()
    }
  }, [searchParams])

  useEffect(() => {
    if (state?.toast) {
      setToast(state.toast)
      window.history.replaceState({}, document.title)
    }
  }, [state])

  useEffect(() => {
    if (pageLoaded && signers) {
      signers.map((signer, i) => {
        if (fetchSignerDocuments) {
          fetchSignerDocuments(signer.id, {
            ...cancelToken.config
          })
            .then(({ status, data: { data } }) => {
              if (status === 200) {
                const newSigners = [...signers];
                const editSigner = newSigners.find(s => s.id === signer.id)
                editSigner.documents_total = data.length
                newSigners[i] = editSigner;
                setSigners(newSigners);
              }
            })
        }
      })
    }

    return () => {
      cancelToken.cancel()
    }
  }, [pageLoaded])

  return (<Dashboard.Content>
    <Dashboard.Header
      title="Seus contatos"
    />

    <Alert show={errors.length} variant="warning" onClose={() => setErrors([])}>
      {errors.map((error) => error)}
    </Alert>

    {!!signers?.length &&
      <Dashboard.Filter>
        <Form id="form-signers-filter"
          onSubmit={handleSubmit}
        >
          <Form.Group>
            <Input.Group>
              <Input.Search
                name="search"
                placeholder="Busque pelo nome ou e-mail do contato"
                onChange={handleFieldValueChange}
                defaultValue={formData.search}
              />
              <Button variant="action" submit>
                <span className="d-lg-none">
                  <Icon id="icon-zoom" size="20" />
                </span>
                <span className="d-none d-lg-block">
                  BUSCAR
                </span>
              </Button>
            </Input.Group>
            <Dashboard.FixedBottom>
              {Api('signer/create') && <Button variant="primary"
                onClick={() => setModalAdd(true)}
              >
                <Icon id="icon-person_add" className="me-1" size="18" />
                NOVO CONTATO
              </Button>}
              <Button variant="primary"
                className="btn-scroll-top"
                onClick={() => setModalAdd(true)}
              >
                <Icon id="icon-add" />
              </Button>
            </Dashboard.FixedBottom>
          </Form.Group>
        </Form>
      </Dashboard.Filter>
    }

    {signers && !signers.length
      ? <EmptyList
        icon="icon-person"
        title="Você ainda não possui contatos na sua lista"
        content={<>
          <p>
            Adicione o seu primeiro contato para enviar documentos no botão Novo Contato.
          </p>
          <Button variant="primary"
            onClick={() => setModalAdd(true)}
          >
            <Icon id="icon-person_add" className="me-1" size="16" />
            NOVO CONTATO
          </Button>
        </>}
      />
      : <>
        <Table className="table-bordered table-hover"
          rows={signers}
          header={[
            { name: 'Nome' },
            { name: 'E-mail', className: 'd-none d-sm-table-cell' },
            { name: '' }
          ]}
          renderRow={(signer) => {
            return (<tr key={signer.id}>
              <td>
                <Link to={`/dashboard/signers/${signer.id}/view`} className="signer-header">
                  <Avatar placeholder={signer.full_name[0] || signer.email[0]} />
                  <div className="signer-title">{signer.full_name}</div>
                  {(fetchSignerDocuments) &&
                    signerDocumentsMessage(signer.documents_total)
                  }
                </Link>
              </td>
              <td className="d-none d-sm-table-cell">
                {signer.email}
              </td>
              <td className="col-action">
                <TableActions
                  signerId={signer.id}
                  setModalRemove={setModalRemove}
                />
              </td>
            </tr>);
          }}
        />
        {pagination &&
          <Pagination {...pagination} />
        }
      </>
    }

    <Modal.Alert show={modalRemove}
      variant="danger"
      icon="icon-trash"
      title="Deseja realmente excluir esse contato? "
      description="Você pode editar ele se preferir."
      onClose={() => setModalRemove(false)}
    >
      <Modal.Footer>
        <Button variant="info"
          onClick={() => handleEdit(modalRemove.id)}
        >
          <Icon id="icon-edit" className="me-1" size="16" />
          EDITAR
        </Button>
        <Button variant="danger" submit
          onClick={() => handleRemove(modalRemove.id)}
          loading={removeLoading}
        >
          <Icon id="icon-trash" className="me-1" size="16" />
          REMOVER
        </Button>
      </Modal.Footer>
    </Modal.Alert>

    <ModalSigner show={modalAdd}
      onClose={setModalAdd}
      setToast={setToast}
      setModalError={setModalError}
      handleFetchSigners={handleFetchSigners}
    />

    <Modal.Alert show={!!modalError}
      variant="danger"
      icon="icon-danger"
      title={modalError}
      onClose={() => setModalError(false)}
    >
      <Modal.Footer>
        <Button variant="info" submit
          onClick={() => setModalError(false)}
        >
          OK, FECHAR
        </Button>
      </Modal.Footer>
    </Modal.Alert>

    <Toast show={toast} onClose={() => setToast('')}>
      {toast}
    </Toast>
  </Dashboard.Content>);
}

const TableActions = ({ signerId, setModalRemove }) => {
  const navigate = useNavigate()
  const dropdownRef = useRef()

  const actionsButtons = [
    {
      icon: 'icon-visibility',
      text: 'VISUALIZAR',
      onClick: () => navigate(`/dashboard/signers/${signerId}/view`)
    },
    Api('signer/update') && {
      icon: 'icon-edit',
      text: 'EDITAR',
      onClick: () => navigate(`/dashboard/signers/${signerId}/edit`)
    },
    Api('signer/delete') && {
      icon: 'icon-trash',
      text: 'EXCLUIR',
      onClick: () => setModalRemove({ id: signerId })
    }
  ]

  const { icon, text, onClick } = actionsButtons[0]

  return (<div className="table-actions">
    <div className="d-none d-xl-table-cell">
      <Button variant="light"
        className="has-hover"
        as={Button}
        onClick={() => onClick()}
      >
        <Icon id={icon} className="me-1" size="18" />
        {text}
      </Button>
    </div>
    <Dropdown
      ref={dropdownRef}
      align="right"
      variant="action"
      button={<Icon id="icon-more_vert" />}
    >
      {actionsButtons.map(({ icon, text, onClick }, i) => {
        if (!text) {
          return;
        }

        return (<Dropdown.Item key={i}
          as={Button}
          onClick={() => onClick()}
        >
          <Icon id={icon} className="me-1" size="20" />
          {text}
        </Dropdown.Item>)
      })}
    </Dropdown>
  </div>)
}

const ModalSigner = ({ show, onClose, setToast, setModalError, handleFetchSigners }) => {
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    full_name: '',
    email: ''
  })

  const handleSubmit = (formData) => Api('signer/create')(formData)
    .then(({ status }) => {
      if (status === 200) {
        handleClose()
        handleFetchSigners()
        setToast('Contato adicionado com sucesso')
      }
    })

  const handleClose = () => {
    setFormData({})
    setValidated(false)
    setLoading(false)
    onClose()
  }

  return (<Modal show={show}
    onHide={handleClose}
    centered
  >
    <Modal.Header>
      <Button variant="icon" className="btn-close"
        onClick={handleClose}
      >
        <Icon id="icon-close" size="24" />
      </Button>
      <Modal.Title as={'div'} className="h2">
        Adicionando novo contato
      </Modal.Title>
      Informe abaixo os dados do contato para o qual você deseja enviar documentos para assinatura.
    </Modal.Header>
    <Form id="form-signer-new"
      onSubmit={handleSubmit}
      size="lg"
      ComponentBody={Modal.Body}
      ComponentFooter={Modal.Footer}
      controls={[
        {
          Component: Input,
          name: 'full_name',
          autoComplete: 'off',
          defaultValue: formData.full_name,
          label: 'Nome',
          required: true,
          autoFocus: true
        },
        {
          Component: Input.Email,
          name: 'email',
          autoComplete: 'off',
          defaultValue: formData.email,
          label: 'E-mail',
          required: true
        }
      ]}
      buttons={[
        {
          variant: 'primary', submit: true, children: <>
            <Icon id="icon-check" className="me-2" size="18" />
            SALVAR CONTATO
          </>
        }
      ]}
    >
    </Form>
  </Modal>)
};

export default Signers;